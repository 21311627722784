import React, { useEffect, useState, useRef } from "react";
import { set } from "react-native-reanimated";
import {
  validateEmptyFields,
  handleValidatorInputsTypes,
  InitForm,
} from "../../utils/validation/forms";
import validationEmail from "../../utils/validation/email";
import { api, helpers } from "../../import";
import { EventRegister } from "../../modules/EventRegister";
import {
  EVENT_SHOW_MODAL_INFO,
  EVENT_SHOW_MODAL_INFO_REGISTER,
} from "../../constants/Events";
import locales_es from "../../locales/es";
import { Routes, Colors } from "../../configs";
import { useHistory, useLocation } from "react-router-dom";
import AuthService from "../../modules/authService";

interface SendValidateEmailProps {
  emailToValidate?: string;
  register?: boolean;
  setOverlay: (value: boolean) => void;
}

const useSendValidateEmail = ({
  emailToValidate,
  register,
  setOverlay,
}: SendValidateEmailProps) => {
  const history = useHistory();
  const location = useLocation();
  const auth = new AuthService();
  const [errors, setErrors] = useState<any>([]);
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [errorMessages, setErrorMessages] = useState<any>([]);
  const [enableButton, setEnableButton] = useState(false);
  const emailMaxLength = 75;
  const emailRef = React.createRef();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [counter, setCounter] = useState(1800);
  const [isCounterActive, setIsCounterActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [serverLoad, setServerLoad] = useState(false);

  const formatSeconds = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (totalSeconds % 60).toString().padStart(2, "0");

    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (emailToValidate) {
      setEmail(emailToValidate);
    }
  }, [emailToValidate]);

  const logout = () => {
    auth.logout(true, false, true);
  };

  const goBack = () => {
    history.push(`/${Routes.SignUp}`);
  };
  useEffect(() => {
    let intervalId: any;

    if (isCounterActive && counter > 0) {
      intervalId = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else if (counter === 0) {
      setIsCounterActive(false);
    }

    return () => clearInterval(intervalId);
  }, [isCounterActive, counter]);

  useEffect(() => {
    const validation = validationEmail(email);
    setIsValidEmail(validation);
  }, [email]);

  useEffect(() => {
    api.getTimeLeftToVerification().then((res: any) => {
      if (res.status) {
        setEmail(res.data.email);
        if (res.data.time_left > 0) {
          setCounter(res.data.time_left);
          setIsCounterActive(true);
        } else if (res.data.time_left <= 0) {
          setIsCounterActive(false);
          setCounter(0)
        }
        setServerLoad(true);
      }
    })
  }, []);

  const initValues = {
    email: "",
  };
  const initForm: InitForm = {
    setErrors,
    errors,
    setErrorMessages,
    errorMessages,
    setEnableButton,
    initValues,
  };
  const maxLengthEmail = (value: string) => {
    if (value.length >= emailMaxLength) {
      setErrorMessages({
        ...errorMessages,
        email: `El correo no puede tener más de ${emailMaxLength} caracteres`,
      });
      setErrors([...errors, "email"]);
    }
  };

  const onBlurEmail = (inputStr: string, email: string) => {
    maxLengthEmail(email);
    handleValidatorInputsTypes(initForm, email, "email", setEmail, "email", 8);
  };

  const resendEmailVerification = () => {
    if (email === "" || loading) {
      return;
    }
    setLoading(true);
    api
      .resendEmailVerification(email)
      .then((res) => {
        setLoading(false);
        setErrorResponse(res.message);
        setCounter(1800);
        setIsCounterActive(true);
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.successModal.title,
          message: res.message,
        });
      })
      .catch((err: any) => {
        setLoading(false);
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.errorModal.title,
          message: helpers.getErrorMsg(err),
        });
      });
  };

  return {
    errors,
    email,
    setEmail,
    setErrors,
    confirmEmail,
    setConfirmEmail,
    errorMessages,
    setErrorMessages,
    enableButton,
    setEnableButton,
    initForm,
    maxLengthEmail,
    onBlurEmail,
    emailRef,
    isValidEmail,
    counter,
    setCounter,
    isCounterActive,
    setIsCounterActive,
    formatSeconds,
    resendEmailVerification,
    goBack,
    location,
    logout,
    serverLoad
  };
};

export default useSendValidateEmail;
