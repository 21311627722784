import React, { useEffect, useState } from "react";
import scale from "../../../utils/scale";
import Theme from "../../../style/Theme";
import { StyleSheet } from "react-native";
import Routes from "../../../configs/Routes";
import locales_es from "../../../locales/es";
import { useHistory } from "react-router-dom";
import TranslationService from '../../../locales';
import iconSuccess from '../../../images/img_success.png';

const useEmailValidationSuccess = () => {

    const history: any = useHistory();
    const [translations, setTranslations] = useState<any>(null);
    const translationService:any = new TranslationService();

    useEffect(() => {
        translationService.translate().then((translation:any) => {
        setTranslations(translation);
        });
        
    }, []);

    const continueFn = () => {
        history.replace(Routes.BasicInformation);
    }

    const styles = StyleSheet.create({
        container: {
        flex: 1,
        ...Theme.center,
        paddingHorizontal: 24,
        },
        buttonLinear: {
        paddingHorizontal: 32,
        marginTop: 32,
        },
        successImage: {
        width: scale(160, true),
        height: scale(160, true),
        marginBottom: scale(55, true),
        },
    });
    
    return {
        scale,
        styles,
        continueFn,
        locales_es,
        iconSuccess,
        translations,
    };
};

export default useEmailValidationSuccess;
