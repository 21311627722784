import axios from 'axios';
import promise from 'promise';
import {userDataLocalStorage} from '../models/constants';
import {EventRegister} from "./EventRegister";
import {EVENT_FORBIDDEN} from "../constants/Events";
// import {EventRegister} from 'react-native-event-listeners';

// Add a request interceptor
const axiosInstance = axios.create();

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

axiosInstance.interceptors.request.use(
  async config => {
    config.headers = headers;

    let userData = localStorage.getItem(userDataLocalStorage);
    userData = JSON.parse(userData);

    if (userData && userData.token) {
      // config.headers.authorizationjwt = userData.token;
      config.headers.authorization = 'Bearer ' + userData.token;
    }

    return config;
  },
  error => {
    // Do something with request error
    console.log('ERROR:');
    console.log(error);
    return promise.reject(error);
  },
);

// Add line to tag 1.1.1
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  (error) => {
    const publicRoute =
      (window.location.pathname === "/login" ||
        window.location.pathname === "/register" ||
        window.location.pathname === "/forgot-password" ||
        window.location.pathname === "/reset-password" ||
        window.location.pathname === "/services" ||
        window.location.pathname.includes("/ResetPassword")  || 
        window.location.pathname.includes("CreatePassword")) ??
      false;
    if (error.response.status === 401 && !publicRoute) {
      EventRegister.emit(EVENT_FORBIDDEN, { status: 401 });
    } else if (error.response.status === 403 && !publicRoute && (window.location.pathname !== "/Home" || (error.response.data.message.includes('Debes validar tu casilla de correo para ingresar')))) {
      EventRegister.emit(EVENT_FORBIDDEN, {
        status: 403,
        message: error.response.data
        ? error.response.data.message
          ? error.response.data.message
          : ''
        : '',
       });
    }
    return promise.reject(error);
  },
);

export default axiosInstance;
