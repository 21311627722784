const { REACT_APP_API_URL = "https://api.rcta.me" } = process.env;

export const clarityProjectId = () => {
  switch (REACT_APP_API_URL) {
    case "http://localhost:8000":
      return "oxxwuqxm69";
    case "https://api.dev.rcta.me":
      return "oxxwuqxm69";
    case "https://api.qa.rcta.me":
      return "oxxwuqxm69";
    case "https://api.hml.rcta.me":
      return "oxxwujyy5j";
    case "https://api.rcta.me":
      return "oxxqd0dmgl";
    default:
      return "oxxwuqxm69";
  }
};
