import React, { useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ImageBackground,
  ActivityIndicator,
  Image,
  Switch,
} from "react-native";
import Text from "../../../components/Text";
import Theme from "../../../style/Theme";
import InputApp from "../../../components/InputApp";
import { Colors } from "../../../configs";
import HeaderButton from "../../../components/HeaderButton";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import FullScreenLoading from "../../../components/fullscreenLoading";
import Overlay from "../../../components/Overlay";
import iconNext from "../../../images/ic_next@3x.png";
import locales_es from "../../../locales/es";
import ImgLoadAndCrop from "../../../components/imgLoadAndCrop";
import AddSignature from "../../AddSignature";
import iconWarning from "../../../images/img_warning@3x.png";
import { SOURCE_ICON } from "../../../images";
import Stepper from "../../BasicInformation/BasicInformationDesktop/stepper";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import useOtherInformationPage from "../shared/useOtherInformationPage";
import ButtonBorder from "../../../components/Buttons/ButtonBorder";

interface OtherInformationProps {}

const OtherInformation = (props: OtherInformationProps) => {
  const {
    stamp,
    errors,
    prescriptionProfileId,
    theme,
    imgLoadAndCrop,
    uploadImage,
    setCropping,
    stampRef,
    clinicId,
    translations,
    address,
    setAddress,
    showWarningInfo,
    signatureImage,
    cropping,
    loading,
    loadingText,
    logoImage,
    onBlur,
    onRemoveLogoImage,
    onRemoveSignatureImage,
    onSend,
    openCrop,
    openMenuOption,
    setStamp,
    stampErrorFlag,
    stampRequiredError,
    setStampEditorEnabled,
    defaultStampPreview,
    stampEditorEnabled,
    setStampRequiredError,
    validateCustomStamp,
    nameStep,
    setNameStep,
    setName,
    name,
    onSubmit,
    clinicData
  } = useOtherInformationPage();

  const [overlay, setOverlay] = useState(false);

  const goToAddSignature = () => {
    // history.push(`/${Routes.AddSignature}`, {prescriptionProfileId});
    setOverlay(
      <AddSignature
        width={500}
        height={250}
        showMainModal={(
          title,
          content,
          actions,
          customStyles,
          contentCustomClassName
        ) =>
          props.showModal(
            title,
            content,
            actions,
            customStyles,
            contentCustomClassName
          )
        }
        hideMainLoading={props.hideMainLoading}
        onFinish={() => {
          setOverlay(null);
        }}
        prescriptionProfileId={prescriptionProfileId ? prescriptionProfileId : null}
      />
    );
  };

  const renderWarningSign = () => {
    return (
      <Text size={13} lineHeight={20} marginTop={10} marginLeft={5}>
        <Image
          source={iconWarning}
          style={{ height: 14, width: 14 }}
          resizeMode="contain"
        />{" "}
        {locales_es.nonEditableProfileField}.
      </Text>
    );
  };

  if (overlay) {
    return (
      <View style={styles.container}>
        {overlay && <Overlay>{overlay}</Overlay>}
      </View>
    );
  }
  return (
    <View style={styles.container}>
      {loading && <FullScreenLoading text={loadingText} />}
      <ImgLoadAndCrop
        ref={imgLoadAndCrop}
        silentMode={true}
        image={logoImage}
        inputLabel={"Imagen"}
        imageId={"logoImage"}
        imageState={"logoImage"}
        inputWrapperCustomClassName="col-12 pl-md-0"
        canvasCustomClassName="profile"
        saveCrop={uploadImage}
        deleteCrop={() => console.log("deleteCrop")}
        deletedImage={() => console.log("deleteImage")}
        onCropping={() => setCropping(true)}
        onStopCropping={() => setCropping(false)}
      />
      {!cropping && !nameStep && (
        <View style={styles.content}>
          {<Stepper step={2} hasTwoSteps={clinicData && !clinicData.paylink_enabled} />}
          <View style={styles.headerWrapper}>
            <HeaderButton />
            <Text size={32} lineHeight={36} bold>
              {locales_es.otherInfo_page.title}
            </Text>
            <Text
              size={16}
              lineHeight={18}
              marginTop={8}
              style={{ maxWidth: "100%" }}
            >
              {locales_es.prescriptionInfo}
            </Text>
          </View>
          <View style={styles.pageContent}>
            <View style={styles.backgroundImageWrapper}>
              <Image source={SOURCE_ICON.rx} style={styles.backgroundImage} />
            </View>
            <View style={styles.formContainer}>
              <View style={styles.form}>
                <View style={[styles.formRow]}>
                  <View style={styles.flexGrow}>
                    <Text size={17} lineHeight={20} bold>
                      Logo de Consultorio
                    </Text>
                    {logoImage === null ? (
                      <ActivityIndicator />
                    ) : logoImage ? (
                      <>
                        <TouchableOpacity
                          onPress={clinicId ? showWarningInfo : openCrop}
                          style={styles.licenseImage}
                        >
                          <ImageBackground
                            resizeMode="contain"
                            style={styles.image}
                            source={logoImage}
                          />
                        </TouchableOpacity>
                        {!clinicId && (
                          <TouchableOpacity
                            style={Theme.alignSelfCenter}
                            onPress={onRemoveLogoImage}
                          >
                            <Text
                              size={13}
                              lineHeight={16}
                              color={Colors.GrayBlue}
                              semibold
                              center
                              marginTop={24}
                              textDecorationLine={"underline"}
                            >
                              {locales_es.erase}
                            </Text>
                          </TouchableOpacity>
                        )}
                      </>
                    ) : (
                      <TouchableOpacity
                        style={styles.imgUpload}
                        onPress={openCrop}
                      >
                        <View style={{ alignItems: "center" }}>
                          <Image
                            source={{
                              uri: SOURCE_ICON.photoLibrary,
                              width: 50,
                              height: 50,
                            }}
                            style={{ tintColor: "grey" }}
                          />
                          <Text size="20" lineHeight={20}>
                            SUBIR IMAGEN{" "}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                    {clinicId && (
                      <View style={{ alignItems: "center", marginTop: 10 }}>
                        {renderWarningSign()}
                      </View>
                    )}
                  </View>
                  <View style={styles.flexGrow}>
                    <Text size={17} lineHeight={20} bold>
                      {locales_es.signature}
                    </Text>
                    {signatureImage === null ? (
                      <ActivityIndicator />
                    ) : signatureImage ? (
                      <>
                        <TouchableOpacity
                          onPress={goToAddSignature}
                          style={styles.licenseImage}
                        >
                          <ImageBackground
                            resizeMode="contain"
                            style={styles.image}
                            source={signatureImage}
                          />
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={Theme.alignSelfCenter}
                          onPress={onRemoveSignatureImage}
                        >
                          <Text
                            size={13}
                            lineHeight={16}
                            color={Colors.GrayBlue}
                            semibold
                            center
                            marginTop={24}
                            textDecorationLine={"underline"}
                          >
                            {locales_es.erase}
                          </Text>
                        </TouchableOpacity>
                      </>
                    ) : (
                      <TouchableOpacity
                        style={styles.imgUpload}
                        onPress={goToAddSignature}
                      >
                        <View style={{ alignItems: "center" }}>
                          <Image
                            source={{
                              uri: SOURCE_ICON.photoLibrary,
                              width: 50,
                              height: 50,
                            }}
                            style={{ tintColor: "grey" }}
                          />
                          <Text size="20" lineHeight={20}>
                            AGREGAR FIRMA{" "}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
                <View style={styles.formRow}>
                  <View style={styles.flexGrow}>
                    <InputApp
                      title={locales_es.prescriptionAddress}
                      placeholder={locales_es.prescriptionAddress}
                      marginTop={38}
                      onChangeText={setAddress}
                      value={address}
                      borderColor={
                        errors.includes("address")
                          ? theme.lineErrorColor
                          : theme.innearColor
                      }
                      onBlur={() => onBlur("address")}
                      blurOnSubmit={true}
                      // onSubmitEditing={onSubmit}
                      autoCapitalize="words"
                      returnKeyType="next"
                      autoFocus={false}
                      onSubmitEditing={() => {
                        stampRef.current.focus();
                      }}
                      editable={!clinicId}
                      onPress={clinicId ? showWarningInfo : null}
                      // onPress={onGoToChangeAddress}
                    />
                    {clinicId && renderWarningSign()}
                  </View>
                </View>
                <View style={styles.formRow}>
                  <View style={styles.flexGrow}>
                    <Text size={17} lineHeight={40} bold>
                      {translations ? translations.stamp : ""}
                    </Text>
                    <View style={styles.switchContainer}>
                      <Text style={styles.switchLabel}>
                        {translations?.customText}
                      </Text>
                      <Switch
                        value={!!stampEditorEnabled}
                        onValueChange={(e: any) => {
                          setStampEditorEnabled(e);
                          if (!stamp || stamp == "") {
                            setStamp(defaultStampPreview);
                          }
                        }}
                      />
                    </View>
                    {!stampEditorEnabled ? (
                      <View
                        style={{
                          padding: "1em",
                          border: "1px solid lightgray",
                          borderRadius: 8,
                          marginTop: "1em",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "1.1em",
                            fontWeigth: "bold",
                            color: "black",
                          }}
                        >
                          {defaultStampPreview}
                        </Text>
                      </View>
                    ) : (
                      <>
                        <InputApp
                          style={{ height: 95, padding: 10 }}
                          forwardRef={stampRef}
                          placeholder={
                            translations ? translations.stampPlaceholder : ""
                          }
                          value={stamp}
                          marginTop={10}
                          onChangeText={(text: any) => {
                            if (text === "") {
                              setStampRequiredError(true);
                            } else if (stampRequiredError) {
                              setStampRequiredError(false);
                            }
                            validateCustomStamp({ stamp: text });
                            setStamp(text);
                          }}
                          borderColor={
                            stampRequiredError
                              ? theme.lineErrorColor
                              : theme.innearColor
                          }
                          autoCapitalize="words"
                          returnKeyType="next"
                          autoFocus={false}
                          multiline={true}
                        />
                        <Text
                          size={13}
                          lineHeight={16}
                          color={
                            stampErrorFlag || stampRequiredError
                              ? "red"
                              : "#e67e22"
                          }
                          marginTop={5}
                          marginLeft={5}
                        >
                          {stampRequiredError
                            ? locales_es.stampRequired
                            : locales_es.stampLimitWarning}
                        </Text>
                      </>
                    )}
                  </View>
                </View>
                <View style={styles.cta}>
                  <ButtonLinear
                    white
                    title={locales_es.continue}
                    style={styles.sendButton}
                    children={
                      <Image source={iconNext} style={styles.buttonChildren} />
                    }
                    onPress={onSend}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      )}
      {nameStep && (
          <View style={styles.modalContainer}>
            <Text size={20} lineHeight={24} bold marginTop={55}>
              {translations && translations.otherInformationAsign
                ? translations.otherInformationAsign
                : locales_es.otherInformationAsign}
            </Text>
            <View style={{ width: "100%" }}>
              <InputApp
                onChangeText={setName}
                value={name}
                borderColor={
                  errors.includes("name")
                    ? theme.lineErrorColor
                    : theme.innearColor
                }
                onBlur={() => onBlur("name")}
                blurOnSubmit={true}
                autoCapitalize="words"
                returnKeyType="go"
                autoFocus={false}
                onSubmitEditing={onSubmit}
              />
            </View>
            <Text size={15} lineHeight={24} marginTop={16} center>
              {translations && translations.otherInformationInfo
                ? translations.otherInformationInfo
                : locales_es.otherInformationInfo}
            </Text>
            <ButtonLinear
              white
              title={"Guardar"}
              style={{ paddingHorizontal: 32 }}
              onPress={onSubmit}
            />
            <ButtonBorder
              style={{ marginTop: 10, paddingHorizontal: 32 }}
              title={"Volver"}
              onPress={() => setNameStep(false)}
              placeholder
            />
          </View>
        )}
    </View>
  );
};

export default OtherInformation;

const styles = StyleSheet.create({
  licenseImage: {
    marginTop: 16,
    minHeight: 170,
    borderRadius: 8,
    backgroundColor: "#fff",
    paddingHorizontal: 52,
  },
  sendButton: {
    marginTop: 52,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  modalContainer: {
    ...Theme.container,
    ...Theme.center,
    flex: 1,
    position: "absolute",
    zIndex: 900,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: "#fff",
  },
  nameStepWrapper: {
    backgroundColor: "#fff",
  },
  buttonLinear: {
    marginTop: 32,
  },
  container: { flex: 1, minHeight: "100%" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  headerWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    marginTop: 32,
  },
  pageContent: {
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  backgroundImageWrapper: {
    width: "40%",
    minWidth: 400,
    alignItems: "flex-start",
    marginBottom: 32,
  },
  backgroundImage: { width: 420, height: 380 },
  formContainer: { flex: 1, minWidth: 400 },
  form: { marginTop: 24 },
  formRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 16,
  },
  cta: { width: 200 },
  flexGrow: { flex: 1 },
  imgUpload: {
    flex: 1,
    border: "2px dashed grey",
    borderRadius: 16,
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    padding: 48,
  },
});
