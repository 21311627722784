import React, { useRef, useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Image,
  TouchableWithoutFeedback,
  Animated,
  Easing,
  TouchableOpacity,
  Alert,
} from "react-native";
import Text from "../../components/Text";
import { Colors, Routes } from "../../configs";
import Theme from "../../style/Theme";
import scale from "../../utils/scale";
import ButtonLinear from "../../components/Buttons/ButtonLinear";
import Line from "../../components/Layout/Line";
import locales_es from "../../locales/es";
import { useTheme } from "../../configs/Theme";
import { useHistory } from "react-router-dom";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import { showModalInfo } from "../../modules/ViewHelpers";
import Loading from "../../components/Loading";
import Collapsible from "react-collapsible";
import chevronIcon from "../../images/ic_arr_down.png";
import lockActiveIcon from "../../images/ic_lock_active.png";
import checkboxActiveIcon from "../../images/ic_checkbox_active.png";
import editIcon from "../../images/ic_edit.png";
import healthNormalIcon from "../../images/ic_health_normal.png";
import trashIcon from "../../images/ic_trash.png";
import photoLibraryIcon from "../../images/ic_photo_library.png";

interface PrescriptionProfileProps {
  deleteProfile: (id: string, callback: () => void) => void;
}

const PrescriptionProfile = (props: PrescriptionProfileProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const api = new APIService();
  const helpers = new Helpers();

  const goToDocumentVisor = (pdfSource) => {
    helpers.openLink(pdfSource.uri);
  };

  const onCreateTestPrescription = () => {
    setLoading(true);
    // setOptions({headerShown: false});
    api
      .testPrescription(props.id)
      .then((res: any) => {
        // setOptions({headerShown: true});
        setLoading(false);
        goToDocumentVisor({ uri: res.data.url });
      })
      .catch((err: any) => {
        // setOptions({headerShown: true});
        setLoading(false);
        helpers.processError(err);
      });
  };

  const animatedIconRotation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedIconRotation, {
      duration: 150,
      easing: Easing.linear,
      toValue: isCollapsed ? 0 : 180,
      useNativeDriver: true,
    }).start();
  }, [isCollapsed]);

  const deletePrescriptionProfile = () => {
    props.deleteProfile(props.id, setLoading);
  };

  const editProfile = () => {
    history.push(`/${Routes.WorkProfile}`, {
      prescriptionProfileId: props.id,
      stepTitle: locales_es.stepOneOfTwo,
      stepNumber: 1,
    });
  };

  const showWarningInfo = () => {
    showModalInfo(
      locales_es.infoModal.title,
      locales_es.nonFullyEditableProfileInfo
    );
  };

  return (
    <View>
      {loading && <Loading />}
      <View>
        <TouchableWithoutFeedback
          onPress={() => {
            props.onProfileSelect
              ? props.onProfileSelect(props.id)
              : setIsCollapsed(!isCollapsed);
          }}
        >
          <View
            style={{
              flex: 1,
              marginHorizontal: 24,
              paddingBottom: 10,
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <View
              style={{
                flex: 0.7,
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              {props.disabled === false ? (
                <View style={{ marginRight: 10 }}>
                  <Image
                    style={styles.icons}
                    source={lockActiveIcon}
                    resizeMode="contain"
                  />
                </View>
              ) : props.onProfileSelect ? (
                <View style={{ marginRight: 10 }}>
                  {props.checked ? (
                    <Image
                      style={styles.icons}
                      source={checkboxActiveIcon}
                      resizeMode="contain"
                    />
                  ) : (
                    <Image
                      source={checkboxActiveIcon}
                      resizeMode="contain"
                      style={[styles.icons, { tintColor: Colors.GrayBlue }]}
                    />
                  )}
                </View>
              ) : null}
              <Text marginTop={24} bold size={18}>
                {props.name || "Perfil de Receta"}
              </Text>
            </View>
            <View
              style={{
                flex: 0.3,
                alignItems: "space-between",
                justifyContent: "flex-end",
                flexDirection: "row",
                maxHeight: 20,
              }}
            >
              {props.disabled || props.onProfileSelect ? null : (
                <>
                  <TouchableOpacity
                    style={styles.profileListIcon}
                    onPress={editProfile}
                  >
                    <Image
                      source={editIcon}
                      style={[
                        styles.icons,
                        {
                          tintColor: Colors.TealBlue,
                        },
                      ]}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                  {props.clinic_id ? (
                    <TouchableOpacity
                      style={styles.profileListIcon}
                      onPress={showWarningInfo}
                    >
                      <Image
                        source={healthNormalIcon}
                        style={[
                          styles.icons,
                          {
                            tintColor: Colors.Orange,
                          },
                        ]}
                        resizeMode="contain"
                      />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={styles.profileListIcon}
                      onPress={deletePrescriptionProfile}
                    >
                      <Image
                        source={trashIcon}
                        style={[
                          styles.icons,
                          {
                            tintColor: Colors.Tomato,
                          },
                        ]}
                        resizeMode="contain"
                      />
                    </TouchableOpacity>
                  )}
                </>
              )}
              <TouchableWithoutFeedback
                style={styles.profileListIcon}
                onPress={() => {
                  setIsCollapsed(!isCollapsed);
                }}
              >
                <Animated.View
                  style={{
                    transform: [
                      {
                        rotate: animatedIconRotation.interpolate({
                          inputRange: [0, 360],
                          outputRange: ["0deg", "-360deg"],
                        }),
                      },
                    ],
                  }}
                >
                  <Image
                    source={chevronIcon}
                    style={{
                      width: 20,
                      height: 20,
                      tintColor: isCollapsed
                        ? Colors.MediumTurquoise
                        : Colors.Platinum,
                    }}
                    resizeMode="contain"
                  />
                </Animated.View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        </TouchableWithoutFeedback>
        <Collapsible trigger open={!isCollapsed}>
          <View>
            <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
              Profesión
            </Text>
            <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
              {props.medical_licenses && props.medical_licenses.profession
                ? props.medical_licenses.profession
                : "No posee profesión"}
            </Text>
            <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
              Especialidades
            </Text>
            <View style={styles.viewLanguage}>
              {/* {props.specialties && props.specialties.length ? (
                props.specialties.map((item: any, index: number) => {
                  return (
                    <Text bold size={15} lineHeight={18} key={index}>
                      {index < 1 ? item : " " + item}
                      {index < props.specialties.length - 1 && ","}
                    </Text>
                  );
                })
              ) :  */}
              {props.medical_licenses &&
              props.medical_licenses.specialities &&
              props.medical_licenses.specialities.length ? (
                props.medical_licenses.specialities.map(
                  (item: any, index: number) => {
                    return (
                      <Text bold size={15} lineHeight={18} key={index}>
                        {index < 1 ? item : " " + item}
                        {index <
                          props.medical_licenses.specialities.length - 1 && ","}
                      </Text>
                    );
                  }
                )
              ) : (
                <Text bold size={12} lineHeight={18}>
                  No tiene una especialidad asignada
                </Text>
              )}
            </View>
            {/* {props.additional_fields &&
            props.additional_fields.inputs &&
            props.additional_fields.inputs.length ? (
              props.additional_fields.inputs.map((input, index) => {
                return (
                  <View key={index}>
                    <Text
                      marginTop={12}
                      marginLeft={24}
                      size={13}
                      lineHeight={16}
                    >
                      {input.label}
                    </Text>
                    <Text
                      marginTop={8}
                      marginLeft={24}
                      bold
                      size={15}
                      lineHeight={18}
                    >
                      {input.value}
                    </Text>
                  </View>
                );
              })
            ) :  */}
            {props.medical_licenses ? (
              <>
                <View>
                  <Text
                    marginTop={12}
                    marginLeft={24}
                    size={13}
                    lineHeight={16}
                  >
                    Tipo de Matrícula
                  </Text>
                  <Text
                    marginTop={8}
                    marginLeft={24}
                    bold
                    size={15}
                    lineHeight={18}
                  >
                    {props.medical_licenses.medical_licenses_type?.code}
                  </Text>
                </View>
                <View>
                  <Text
                    marginTop={12}
                    marginLeft={24}
                    size={13}
                    lineHeight={16}
                  >
                    Jurisdicción
                  </Text>
                  <Text
                    marginTop={8}
                    marginLeft={24}
                    bold
                    size={15}
                    lineHeight={18}
                  >
                    {
                      props.medical_licenses.medical_licenses_jurisdiction?.description
                    }
                  </Text>
                </View>
                <View>
                  <Text
                    marginTop={12}
                    marginLeft={24}
                    size={13}
                    lineHeight={16}
                  >
                    Nro. de Matrícula
                  </Text>
                  <Text
                    marginTop={8}
                    marginLeft={24}
                    bold
                    size={15}
                    lineHeight={18}
                  >
                    {props.medical_licenses.identification}
                  </Text>
                </View>
              </>
            ) : (
              <Text bold size={12} lineHeight={18} marginLeft={24}>
                No tiene matrícula asignada
              </Text>
            )}
            <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
              Texto del Sello
            </Text>
            <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
              {props.stamp || "No configurado"}
            </Text>
            <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
              Dirección de la receta
            </Text>
            <Text marginTop={8} marginLeft={24} bold size={15} lineHeight={18}>
              {props.address || "No configurado"}
            </Text>
            <View style={{ flexDirection: "column", marginTop: 36 }}>
              {props.full_logo_image ? (
                <Image
                  resizeMode="contain"
                  style={styles.schoolPhoto}
                  source={{ uri: props.full_logo_image }}
                />
              ) : (
                <>
                  <Image
                    style={[
                      styles.icons,
                      { tintColor: "grey", alignSelf: "center" },
                    ]}
                    source={photoLibraryIcon}
                  />
                  <Text
                    center
                    marginTop={10}
                    size={15}
                    lineHeight={18}
                    marginBottom={10}
                  >
                    No configurado
                  </Text>
                </>
              )}
              <View>
                <Text center marginTop={15} bold size={15} lineHeight={18}>
                  Encabezado de esta receta
                </Text>
              </View>
            </View>
            <Line height={1} marginTop={30} marginBottom={20} />
            <View style={{ flexDirection: "column" }}>
              {props.full_signature_image ? (
                <Image
                  resizeMode="contain"
                  style={styles.schoolPhoto}
                  source={{ uri: props.full_signature_image }}
                />
              ) : (
                <>
                  <Image
                    style={[
                      styles.icons,
                      { tintColor: "grey", alignSelf: "center" },
                    ]}
                    source={photoLibraryIcon}
                  />
                  <Text
                    center
                    marginTop={10}
                    size={15}
                    lineHeight={18}
                    marginBottom={10}
                  >
                    No configurada
                  </Text>
                </>
              )}
              <View>
                <Text center marginTop={15} bold size={15} lineHeight={18}>
                  Firma
                </Text>
              </View>
            </View>
          </View>
          {props.onProfileSelect ? null : (
            <ButtonLinear
              white
              style={styles.buttonUpdate}
              title={"Ver Receta de Ejemplo"}
              onPress={onCreateTestPrescription}
            />
          )}
        </Collapsible>
      </View>
    </View>
  );
};

export default PrescriptionProfile;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  box: {
    marginTop: 16,
    borderRadius: 16,
    paddingBottom: 24,
    marginHorizontal: 16,
    overflow: "hidden",
  },
  button: {
    borderWidth: 0,
    marginVertical: 10,
  },
  topBox: {
    ...Theme.flexRowSpace,
    paddingTop: 16,
    paddingHorizontal: 24,
  },
  rounded: {
    width: 32,
    height: 32,
    borderRadius: 8,
    backgroundColor: Colors.OysterBay,
    ...Theme.center,
  },
  buttonEdit: {
    width: 24,
    height: 24,
    backgroundColor: Colors.DodgerBlue,
  },
  buttonUpdate: {
    marginHorizontal: 24,
    marginVertical: 12,
  },
  icons: {
    width: 20,
    height: 20,
  },
  images: {
    width: scale(348),
    height: scale(200),
  },
  iconPhone: {
    backgroundColor: Colors.RedNeonFuchsia,
    width: 24,
    height: 24,
    borderRadius: 8,
  },
  flexRow: {
    ...Theme.flexRow,
    marginTop: 16,
    marginLeft: 24,
  },
  contentIcon: {
    width: 40,
    height: 40,
    backgroundColor: Colors.TealBlue,
    ...Theme.center,
    borderRadius: 16,
    marginRight: 16,
  },
  setRow: {
    ...Theme.flexRow,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  viewLanguage: {
    ...Theme.flexRow,
    marginLeft: 24,
    marginTop: 8,
  },
  schoolPhoto: {
    width: "100%",
    height: 56,
    maxHeight: 56,
    // borderRadius: 56,
    marginRight: 8,
    alignSelf: "flex-start",
  },
  viewEducation: {
    ...Theme.flexRow,
    marginLeft: 24,
    marginTop: 8,
  },
  itemAward: {
    marginTop: 24,
    marginLeft: 24,
  },
  profileListIcon: {
    marginHorizontal: 5,
  },
});
