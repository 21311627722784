import React, { memo, useCallback, useState, useEffect } from "react";
import {
  StyleSheet,
  ActivityIndicator,
  View,
  Image,
  TouchableOpacity,
} from "react-native";
import { useHistory } from "react-router-dom";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import FullScreenLoading from "../../../components/fullscreenLoading";
import locales_es from "../../../locales/es";
import { Colors, Routes } from "../../../configs";
import { showModalInfo } from "../../../modules/ViewHelpers";
import { api, dateTimeService, helpers } from "../../../import";
import { SOURCE_ICON } from "../../../images";
import Text from "../../../components/Text";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import Card, { Icon } from "../../../components/Card";
import Stat from "./Stat";
import IncompleteProfileView from "../shared/IncompleteProfile";
import ValidateUserModal from "../shared/ValidateUserModal";
import useHomePage from "../shared/useHomePage";

interface IStat {
  color: string;
  number: number;
  title: string;
  icon: string;
}

interface ICertificate {
  patient?: { name: string; lastname: string };
  medical_certificates_practices?: {
    medical_practice?: { code: string; name: string };
  }[];
  text: string;
  date: string;
  full_file_url: string;
}

interface IPrescription {
  patient?: { name: string; lastname: string };
  medicines?: {
    drug: string;
    presentation: string;
  }[];
  full_file_url: string;
  date: string;
}

const HomeDesktop = memo(() => {
  const history = useHistory();
  const [stats, setStats] = useState<IStat[] | null>(null);
  const [certificates, setCertificates] = useState<ICertificate[]>();
  const [prescriptions, setPrescriptions] = useState<IPrescription[]>();
  const onPencilPress = useCallback(() => {
    history.push(`/${Routes.AddPrescription}`);
  }, [history]);

  const {
    loading,
    incompleteProfile,
    onPressAccountResend,
    userData,
    validateUserModal,
    setValidateUserModal,
    medicinePermissionFlag,
    userStatusEnabled,
    prescriptionProfiles
  } = useHomePage();
  const fullName = `${userData?.professional_prefix?.name ?? ""} ${
    userData?.name ?? ""
  } ${userData?.lastname ?? ""}`;

  useEffect(() => {
    if(userData
     && !incompleteProfile
     && (prescriptionProfiles &&prescriptionProfiles.length >0)){

    api
      .getMyStats()
      .then(
        (res: {
          data: {
            patients_quantity: number;
            medical_prescriptions_quantity: number;
            medical_certificates_quantity: number;
          };
        }) => {
          const data = res.data;
          const parsedStats = [
            {
              color: Colors.TealBlue,
              number: data.patients_quantity,
              title: locales_es.patients,
              icon: SOURCE_ICON.additionalInformation,
            },
            {
              color: Colors.DodgerBlue,
              number: data.medical_prescriptions_quantity,
              title: locales_es.prescriptions,
              icon: SOURCE_ICON.prescriptions,
            },
            {
              color: Colors.Orange,
              number: data.medical_certificates_quantity,
              title: locales_es.otherPrescriptions,
              icon: SOURCE_ICON.exp,
            },
          ];
          setStats(parsedStats);
        }
      )
      .catch((err) => {
        helpers.processError(err);
      });




              api
                .getMyCertificatesPaginated()
                .then((res) => {
                  setCertificates(res.data);
                })
                .catch(() => setCertificates([]));

            api
                .getMyPrescriptionsPaginated()
                .then((res) => {
                  setPrescriptions(res.data);
                })
                .catch(() => setPrescriptions([]));
    }


  }, [loading, incompleteProfile, userData,prescriptionProfiles]);

  return (
    <div style={styles.container}>
      {loading ? <FullScreenLoading /> : null}
      <View style={styles.content}>
        {userData && (
          <View>
            <Text size={38} lineHeight={36} bold>
              Hola {fullName}
            </Text>
            <Text size={18} lineHeight={18} marginTop={8}>
              {locales_es.home_page.subtitle}
            </Text>
          </View>
        )}
        {!stats ? (
          <ActivityIndicator size="large" style={{ marginTop: 60 }} />
        ) : (
          <View style={styles.statContainer}>
            {stats.map((stat) => (
              <View key={stat.title}>
                <Stat {...stat} />
              </View>
            ))}
          </View>
        )}

        <View style={styles.cardsContainer}>
          <View style={styles.cardWrapper}>
            <Card
              header={{
                icon: SOURCE_ICON.prescriptions,
                title: locales_es.home_page.last_prescriptions,
              }}
            >
              {!prescriptions ? (
                <ActivityIndicator size="large" style={{ marginTop: 60 }} />
              ) : (
                <View style={styles.card}>
                  {prescriptions.length ? (
                    <>
                      <View style={styles.cardMainContentWrapper}>
                        {prescriptions.map(
                          ({ full_file_url, patient, medicines, date }) => (
                            <View style={styles.cardMainContent} key={full_file_url}>
                              <Icon icon={SOURCE_ICON.prescriptions} />
                              <View style={{ flex: 1 }}>
                                <Text bold>
                                  {patient
                                    ? `${patient.name} ${patient.lastname}`
                                    : locales_es.home_page.no_name_patient}
                                </Text>
                                <Text color="#a1a1a1" size={12} lineHeight={16}>
                                  {dateTimeService.reverseAPIStringDate(date)}
                                </Text>
                                <Text>
                                  {medicines ? (
                                    <ul style={styles.cardList}>
                                      {medicines.map((medicine) => (
                                        <li style={styles.cardListItem} key={medicine.drug}>
                                          <Text color="black">{`${medicine.drug} ${medicine.presentation}`}</Text>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </Text>
                              </View>
                              <TouchableOpacity
                                style={styles.cardItemAction}
                                onPress={() => helpers.openLink(full_file_url)}
                              >
                                <Image
                                  source={SOURCE_ICON.eyeOn}
                                  style={styles.cardItemIcon}
                                />
                              </TouchableOpacity>
                            </View>
                          )
                        )}
                      </View>
                      <View style={styles.cardAction}>
                        <ButtonLinear
                          white
                          title={locales_es.home_page.actions.see_all}
                          onPress={() => {
                            history.push(`/${Routes.Prescriptions}`);
                          }}
                        />
                      </View>
                    </>
                  ) : (
                    <Text size={15}>
                      {locales_es.home_page.no_prescriptions}
                    </Text>
                  )}
                </View>
              )}
            </Card>
          </View>
          <View style={styles.cardWrapper}>
            <Card
              header={{
                icon: SOURCE_ICON.exp,
                title: locales_es.home_page.last_certificates,
              }}
            >
              {!certificates ? (
                <ActivityIndicator size="large" style={{ marginTop: 60 }} />
              ) : (
                <View style={styles.card}>
                  {certificates.length ? (
                    <>
                      <View style={styles.cardMainContentWrapper}>
                        {certificates.map(
                          ({
                            patient,
                            medical_certificates_practices,
                            text,
                            date,
                            full_file_url,
                          }) => (
                            <View style={styles.cardMainContent} key={full_file_url}>
                              <Icon icon={SOURCE_ICON.exp} />
                              <View style={{ flex: 1 }}>
                                <Text bold>
                                  {patient
                                    ? `${patient.name} ${patient.lastname}`
                                    : locales_es.home_page.no_name_patient}
                                </Text>
                                <Text color="#a1a1a1" size={12} lineHeight={16}>
                                  {dateTimeService.reverseAPIStringDate(date)}
                                </Text>
                                <Text>
                                  {medical_certificates_practices &&
                                  medical_certificates_practices.length ? (
                                    <ul style={styles.cardList}>
                                      {medical_certificates_practices.map(
                                        (certificate) =>
                                          certificate.medical_practice && (
                                            <li style={{ color: "#00c1c1" }} key={certificate.medical_practice.code}>
                                              <Text color="black">
                                                {
                                                  certificate.medical_practice
                                                    .code
                                                }{" "}
                                                -{" "}
                                                {
                                                  certificate.medical_practice
                                                    .name
                                                }
                                              </Text>
                                            </li>
                                          )
                                      )}
                                    </ul>
                                  ) : (
                                    text
                                  )}
                                </Text>
                              </View>
                              <TouchableOpacity
                                style={styles.cardItemAction}
                                onPress={() => helpers.openLink(full_file_url)}
                              >
                                <Image
                                  source={SOURCE_ICON.eyeOn}
                                  style={styles.cardItemIcon}
                                />
                              </TouchableOpacity>
                            </View>
                          )
                        )}
                      </View>
                      <View style={styles.cardAction}>
                        <ButtonLinear
                          white
                          title={locales_es.home_page.actions.see_all}
                          onPress={() => {
                            history.push(`/${Routes.Certificates}`);
                          }}
                        />
                      </View>
                    </>
                  ) : (
                    <Text size={15}>
                      {locales_es.home_page.no_certificates}
                    </Text>
                  )}
                </View>
              )}
            </Card>
          </View>
        </View>
      </View>

      {loading ? (
        <ActivityIndicator />
      ) : medicinePermissionFlag && userStatusEnabled ? (
        <View style={styles.prescriptionActionWrapper}>
          <TouchableOpacity
            onPress={onPencilPress}
            style={styles.prescriptionAction}
          >
            <Text color="#6b6b6b" bold>
              {locales_es.createPrescription}
            </Text>
            <ButtonLinear
              white
              style={styles.prescriptionButton}
              title=""
              onPress={onPencilPress}
            >
              <Image
                source={SOURCE_ICON.plus}
                style={styles.prescriptionIcon}
              />
            </ButtonLinear>
          </TouchableOpacity>
        </View>
      ) : null}

      {validateUserModal ? (
        <ValidateUserModal
          visible={validateUserModal}
          onRequestClose={() => setValidateUserModal(false)}
          onClick={onPressAccountResend}
        />
      ) : null}

      {incompleteProfile ? <IncompleteProfileView /> : null}
    </div>
  );
});

export default HomeDesktop;

const styles = StyleSheet.create<any>({
  container: { flex: 1, minHeight: "100%" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  statContainer: {
    flexDirection: "row",
    marginTop: 32,
    flexWrap: "wrap",
    gap: 24,
    rowGap: 32,
  },
  cardsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 24,
    marginTop: 32,
  },
  cardWrapper: { width: "35%", flex: 1, maxWidth: 600, minWidth: 300 },
  card: { flex: 1 },
  cardMainContentWrapper: { flex: 1, gap: 16 },
  cardMainContent: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 8,
  },
  cardList: { paddingLeft: 16 },
  cardListItem: { color: "#00c1c1" },
  cardItemAction: { alignSelf: "center" },
  cardItemIcon: {
    width: 25,
    height: 25,
    tintColor: Colors.DodgerBlue,
  },
  cardAction: { margin: "auto", width: 150 },
  prescriptionActionWrapper: {
    position: "fixed",
    right: 24,
    bottom: 16,
  },
  prescriptionAction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
  },
  prescriptionButton: {
    borderRadius: 20,
    height: 40,
    width: 40,
    marginTop: 0,
    marginLeft: 8,
  },
  prescriptionIcon: { width: 20, height: 20, tintColor: "white" },
});
