import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  View,
  StyleSheet,
  ActivityIndicator,
  ImageBackground,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
} from "react-native";
import Text from "../../../components/Text";
import Theme from "../../../style/Theme";
import { Colors, Routes } from "../../../configs";
import avatarProfileIcon from "../../../images/avatar/ic_account_normal1.png";
import iconSearch from "../../../images/ic_search_normal@3x.png";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import locales_es from "../../../locales/es";
import { renderAge, renderAgeText } from "../../../modules/ViewHelpers";
import TouchableOpacityHOC from "../../../components/TouchableOpacityHOC";
import InputApp from "../../../components/InputApp";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import { SOURCE_ICON } from "../../../images";
import useMyPatientsPage from "../shared/useMyPatientsPage";
import { FAKE_EMAIL_REGEX } from "../../../models/constants";
import Overlay from "../../../components/Overlay";
import SearchBox from "../../../components/Home/SearchBox";
import Layout from "../../../components/Layout/Layout";
import HeaderButton from "../../../components/HeaderButton";
import SearchPatients from "../../SearchPatients";
import ButtonIcon from "../../../components/Buttons/ButtonIcon";
import { height, width } from "../../../configs/Const";
import CloudAddIcon from "../../../assets/img/cloud-add.svg";
import EditIcon from "../../../assets/img/edit-2.svg";

interface MyPatientsProps {
  source: string;
}

const MyPatientsDesktop = (props: MyPatientsProps) => {
  const {
    selectPatientId,
    patients,
    onPressWhitePlusButton,
    onUserInfoPress,
    goToUserProfile,
    getPatients,
    keySearch,
    history,
    nextPageUrl,
    onKeySearchChange,
    refreshing,
    onGoback,
  } = useMyPatientsPage(props.selectPatientId, props.onSelectPatient);

  const contentTableRef = useRef();

  const onScroll = () => {
    if (contentTableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentTableRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;
      if (isNearBottom && nextPageUrl) {
        getPatients();
      }
    }
  };

  useEffect(() => {
    const listInnerElement = contentTableRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, [refreshing, nextPageUrl]);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.headerWrapper}>
            {props.source && (
              <HeaderButton
                onPress={() => onGoback(props)}
                style={{
                  marginTop: 26,
                  marginLeft: 24,
                }}
              />
            )}
            <Text size={38} lineHeight={36} bold>
              {locales_es.myPatients_page.title}
            </Text>
          </View>
          <View>
            <InputApp
              styleView={styles.inputSearch}
              onChange={onKeySearchChange}
              placeholder="Buscar Paciente"
              isShowIconLeft
              iconLeft={<Image source={iconSearch} style={styles.iconSearch} />}
              autoCorrect={false}
              autoCapitalize="words"
              value={keySearch}
            />
          </View>
          <ScrollView
            style={{ overflow: "auto", height: "100%" }}
            ref={contentTableRef}
          >
            <View style={styles.tableRowHeader}>
              <View style={[styles.columnHeader, { flex: 1 }]}>
                <Text color={"#189394"} bold size={18}>
                  {locales_es.myPatients_page.name}
                </Text>
              </View>
              <View style={styles.columnHeader}>
                <Text color={"#189394"} bold size={18}>
                  {locales_es.myPatients_page.id}
                </Text>
              </View>
              <View style={[styles.columnHeader, { flex: 1 }]}>
                <Text color={"#189394"} bold size={18}>
                  {locales_es.myPatients_page.clinic}
                </Text>
              </View>
              <View
                style={[styles.columnHeader, { minWidth: 150, width: 150 }]}
              >
                <Text color={"#189394"} bold size={18}>
                  {locales_es.myPatients_page.gender}
                </Text>
              </View>
              <View
                style={[styles.columnHeader, { minWidth: 120, width: 120 }]}
              >
                <Text color={"#189394"} bold size={18}>
                  {locales_es.myPatients_page.age}
                </Text>
              </View>
              <View style={[styles.columnHeader, { flex: 1 }]}>
                <Text color={"#189394"} bold size={18}>
                  {locales_es.myPatients_page.email}
                </Text>
              </View>
              <View style={styles.columnHeader}>
                <Text color={"#189394"} bold size={18}>
                  {locales_es.myPatients_page.insurance}
                </Text>
              </View>
            </View>

            {refreshing || (patients && patients.length) ? (
              <View style={styles.tableWrapper}>
                {patients.map((patient, index) => (
                  <TouchableOpacityHOC
                    onPress={
                      selectPatientId
                        ? () => onUserInfoPress(patient)
                        : () => goToUserProfile(patient)
                    }
                    key={index}
                  >
                    <View
                      style={styles.tableRow}
                      key={`${patient.name}_${patient.lastname}_${patient.identification}_${index}`}
                    >
                      <View
                        style={[
                          styles.column,
                          { flex: 1, alignItems: "center" },
                        ]}
                      >
                        <ImageBackground
                          source={{
                            uri:
                              patient.full_profile_image || avatarProfileIcon,
                          }}
                          resizeMode="cover"
                          imageStyle={styles.avatarImage}
                        />
                        <Text size={14}>
                          {patient.name} {patient.lastname}
                        </Text>
                      </View>
                      <View style={styles.column}>
                        <Text size={14}>
                          {patient.identification || "No Especificada"}
                        </Text>
                      </View>
                      <View style={styles.column}>
                        <Text
                          size={14}
                          color={
                            patient.clinic?.name
                              ? Colors.BlueTag
                              : Colors.GreenTag
                          }
                        >
                          <img
                            src={patient.clinic?.name ? CloudAddIcon : EditIcon}
                            style={{
                              marginRight: 5,
                              width: patient.clinic?.name ? 24 : 20,
                              height: patient.clinic?.name ? 24 : 20,
                            }}
                          />
                          {patient.clinic?.name ?? "Propio"}
                        </Text>
                      </View>
                      <View
                        style={[styles.column, { minWidth: 150, width: 150 }]}
                      >
                        <Text size={14}>
                          {patient.gender?.name || "No Especificado"}
                        </Text>
                      </View>
                      <View
                        style={[styles.column, { minWidth: 120, width: 120 }]}
                      >
                        <Text size={14}>
                          {renderAgeText(
                            patient.birth_date,
                            true,
                            true,
                            true
                          ).trim() || "No Especificada"}
                        </Text>
                      </View>
                      <View style={[styles.column, { flex: 1 }]}>
                        <Text size={14}>
                          {FAKE_EMAIL_REGEX.test(patient.email)
                            ? "No Especificado"
                            : patient.email}
                        </Text>
                      </View>
                      <View style={styles.column}>
                        <Text size={14}>
                          {patient.medical_insurance?.name || "Sin cobertura"}
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacityHOC>
                ))}
                {refreshing ? (
                  <ActivityIndicator style={{ marginVertical: 24 }} />
                ) : null}
              </View>
            ) : (
              <Text
                size={14}
                lineHeight={20}
                marginTop={30}
                center
                style={{ marginHorizontal: 24 }}
              >
                {locales_es.myPatients_page.not_found}
              </Text>
            )}
          </ScrollView>
        </View>
        <View
          style={{
            justifyContent: "right",
            alignItems: "end",
            marginBottom: 20,
          }}
        >
          <ButtonIcon
            text={locales_es.myPatients_page.new}
            onPress={onPressWhitePlusButton}
            icon={"plus"}
            imgStyle={styles.prescriptionIcon}
            style={{
              backgroundColor: "#39BFB2",
              height: 40,
              width: 120,
              paddingLeft: 0,
              borderRadius: 50,
              position: "fixed",
              right: 20,
              bottom: 10,
            }}
            iconPosition="left"
            tintColor={Colors.White}
          />
        </View>
      </View>
    </>
  );
};

export default MyPatientsDesktop;

const styles = StyleSheet.create({
  container: {
    minHeight: "95%",
    zIndex: 1000,
  },
  content: {
    overflow: "scroll",
    overflowY: "scroll",
    width: "105%",
    minHeight: "100%",
    height: "100%",
    paddingBottom: 14,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  headerWrapper: { flexDirection: "row", gap: 16, alignItems: "center" },
  tableRowHeader: { display: "flex", flexFlow: "row", height: "10%" },
  columnHeader: {
    minWidth: 150,
    width: "calc(100% / 6)",
    wordBreak: "break-word",
    padding: 16,
    paddingLeft: 0,
  },
  tableRow: { display: "flex", flexFlow: "row" },
  column: {
    minWidth: 150,
    wordBreak: "break-word",
    width: "calc(100% / 6)",
    padding: 16,
    paddingLeft: 0,
    display: "flex",
    flexDirection: "row",
    gap: 16,
    alignItems: "center",
  },
  tableWrapper: { maxHeight: "calc(100vh - 300px)", height: "10%" },
  avatarImage: {
    borderRadius: 20,
    width: 40,
    height: 40,
    position: "relative",
  },
  iconSearch: {
    ...Theme.icons,
    tintColor: Colors.DodgerBlue,
  },
  inputSearch: { maxWidth: 300, paddingTop: 24, paddingBottom: 24 },
  prescriptionActionWrapper: {
    position: "fixed",
    right: 24,
    bottom: 16,
  },
  prescriptionAction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
  },
  prescriptionButton: {
    borderRadius: 20,
    height: 40,
    width: 40,
    marginTop: 0,
    marginLeft: 8,
  },
  prescriptionIcon: {
    width: 20,
    height: 20,
    tintColor: "white",
    marginLeft: 0,
  },
  headerLayout: {
    backgroundColor: "transparent",
    shadowColor: "transparent",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
    paddingTop: Platform.OS === "ios" ? getStatusBarHeight() : 0,
    ...Theme.flexRowSpace,
    paddingBottom: 10,
  },
});
