import React, { memo, useState, useCallback, useEffect } from "react";
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Platform,
  Keyboard,
} from "react-native";
import Text from "../../components/Text";
import { Colors, Routes } from "../../configs";
import InputApp from "../../components/InputApp";
import FullScreenLoading from "../../components/fullscreenLoading";
import ButtonLinear from "../../components/Buttons/ButtonLinear";
import { useHistory, useLocation } from "react-router-dom";
import validationEmail from "../../utils/validation/email";
import { useTheme } from "../../configs/Theme";
// @ts-ignore
import locales_es from "../../locales/es";
import AuthService from "../../modules/authService";
import Helpers from "../../modules/helpers";
import {
  validateEmptyFields,
  handleValidatorInputsTypes,
  InitForm,
} from "../../utils/validation/forms";
import { EventRegister } from "../../modules/EventRegister";
import {
  EVENT_SHOW_MODAL_INFO,
  EVENT_SHOW_MODAL_INFO_REGISTER,
} from "../../constants/Events";
import eyeOnIcon from "../../images/ic_eye_on.png";
import "./styles.css";
import Footer from "../../components/footer";
import { api } from "../../import";

import RequireData from "../../components/RequireData";
import useDimensions from "../../hooks/useDimensions";
import { SOURCE_ICON } from "../../images";
import { set } from "react-native-reanimated";
import { AsyncStorageEnum, setInStorage } from '../../utils/storage';

interface LoginProps {}

interface LocationState {
  showEmailValidation?: boolean;
}

const Login = memo((props: LoginProps) => {
  const { isDesktop } = useDimensions();
  const auth = new AuthService();
  const helpers = new Helpers();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const { theme } = useTheme();
  const [confirmEmailCode, setConfirmEmailCode] = useState(1);
  const location = useLocation();
  const locationState = location.state as { showEmailValidation?: boolean };


  const [confirmEmail, setConfirmEmail] = useState(
    locationState?.showEmailValidation || false
  );

  const [isCounterActive, setIsCounterActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [verificationEmail, setVerificationEmail] = useState("");
  const onShowHidePassword = useCallback(() => {
    setVisiblePassword((prev) => !prev);
  }, []);



  const onSignUp = useCallback(() => {
    /*EventRegister.emit(EVENT_SHOW_MODAL_INFO_REGISTER, {
      title: 'Registro de nuevo médico',
      message: 'Para registrarse, descarga la aplicación en tu teléfono',
    });*/
    history.push(`/${Routes.SignUp}`);
    // history.push(`/${Routes.BasicInformation}`);
  }, [history]);
  const onLogin = () => {
    const errors = validateEmptyFields({ email, password });
    if (errors.length) {
      setErrors(errors);
    } else {
      setLoading(true);
      auth
        .login({email, password})
        .then((res) => {
          setLoading(false);
          setInStorage(AsyncStorageEnum.IDENTIFICATION_VALIDATION, 'true');
          
          if (res.error && (res.error.includes('Debes validar tu casilla de correo') || res.error.includes('Valida tu cuenta en RCTA'))) {
            history.push(`/${Routes.SendValidateEmail}`);
          } else {
            history.push(`/${Routes.Home}`);
          }
        })
        .catch((err: any) => {
          setLoading(false);
          const cleanedErrMessage = removeErrorPrefix(err.message);
          const errMessage = cleanedErrMessage.trim().toLowerCase();
          const errMessages = [
            "Ya posee un código de validación en su casilla de correo. Si no lo encuentra, por favor revise la carpeta de spam.",
            "debes validar tu casilla de correo para ingresar. te hemos enviado un e-mail para validar tu casilla de correo.",
          ].map((msg) => msg.trim().toLowerCase());
          const errMessageNotToken =
            "Valida tu cuenta en RCTA, debes validar tu casilla de correo para poder ingresar."
              .trim()
              .toLowerCase();

          if (errMessages.includes(errMessage) || errMessage.includes(errMessageNotToken)) {
            EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
              title: locales_es.errorModal.title,
              message: cleanedErrMessage,
            });
            history.push(`/${Routes.SendValidateEmail}`);
          } else {
            EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
              title: locales_es.errorModal.title,
              message: cleanedErrMessage,
            });
          }
        });
    }
  };

  const onForgotPassword = useCallback(() => {
    history.push(`/${Routes.Recovery}`);
  }, [history]);

  const onBlurEmail = (inputStr: string, email: string) => {
    maxLengthEmail(email);
    handleValidatorInputsTypes(initForm, email, "email", setEmail, "email", 8);
    // setErrors(errors.filter(item => item !== inputStr));
  };

  useEffect(() => {
    const validation = validationEmail(email);
    setIsValidEmail(validation);
  }, [email]);

  useEffect(() => {
    if (auth.isUnauthorized()) {
      auth.removeUnauthorized();
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.errorModal.noneTitle,
        message: locales_es.errorModal.unauthorizedError,
      });
    }
  });

  const removeErrorPrefix = (text: string): string => {
    const prefix = "Error:";
    return text.startsWith(prefix) ? text.slice(prefix.length).trim() : text;
  };

  const formatSeconds = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (totalSeconds % 60).toString().padStart(2, "0");

    return `${minutes}:${seconds}`;
  };

  const resendEmailVerification = () => {
    setLoading(true);
    api
      .resendEmailVerification(verificationEmail)
      .then((res) => {
        setLoading(false);
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.successModal.title,
          message: res.message,
        });
        setCounter(1800);
        setIsCounterActive(true);
      })
      .catch((err: any) => {
        setLoading(false);
        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
          title: locales_es.errorModal.title,
          message: helpers.getErrorMsg(err),
        });
      });
  };

  useEffect(() => {
    let intervalId: any;

    if (isCounterActive && counter > 0) {
      intervalId = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else if (counter === 0) {
      setIsCounterActive(false);
    }

    return () => clearInterval(intervalId);
  }, [isCounterActive, counter]);

  const passwordRef = React.createRef();
  const emailRef = React.createRef();

  const [errorMessages, setErrorMessages] = useState({
    email: "",
    password: "",
  });
  const [enableButton, setEnableButton] = useState(true);

  const initValues = {
    email,
    password,
  };

  const initForm: InitForm = {
    setErrors,
    errors,
    setErrorMessages,
    errorMessages,
    setEnableButton,
    initValues,
  };

  useEffect(() => {
    if (email.length > 0 && password.length > 0 && errors.length === 0) {
      setEnableButton(false);
    }
  }, [email, password]);

  const emailMaxLength = 75;

  const maxLengthEmail = (value: string) => {
    if (value.length >= emailMaxLength) {
      setErrorMessages({
        ...errorMessages,
        email: `El correo no puede tener más de ${emailMaxLength} caracteres`,
      });
      setErrors([...errors, "email"]);
    }
  };

  return (
    <div
      testID="loginScreen"
      className="w-100 p-3 p-md-0"
      style={{
        flex: 1,
        backgroundColor: isDesktop ? Colors.Snow : theme.backgroundItem,
      }}
    >
      {loading && <FullScreenLoading />}
      <div className="row h-100">
        <div className="d-none d-md-inline-flex col-6">
          <div className="rcta-bg-logo" />
        </div>
        {!confirmEmail && (
          <div
            className="col-12 col-md-6 align-self-center text-center"
            style={{ ...(isDesktop ? styles.desktopContent : {}) }}
          >
            <div
              className={!isDesktop ? "text-center" : ""}
              style={{
                ...styles.textStyle,
                ...(isDesktop ? styles.desktopTitle : {}),
              }}
            >
              {locales_es.login_page.welcome}
            </div>
            <div className="p-3">
              <View style={styles.inputLogin}>
                <RequireData />
                <InputApp
                  testID="email"
                  borderColor={
                    errors.includes("email")
                      ? theme.lineErrorColor
                      : theme.innearColor
                  }
                  title={locales_es.email}
                  placeholder={locales_es.email}
                  value={email}
                  onInput={(value: string) => {
                    handleValidatorInputsTypes(
                      initForm,
                      value,
                      "email",
                      setEmail,
                      "email",
                      8
                    );
                    maxLengthEmail(value);
                  }}
                  onChangeText={(value: string) => {
                    handleValidatorInputsTypes(
                      initForm,
                      value,
                      "email",
                      setEmail,
                      "email",
                      8
                    );
                    maxLengthEmail(value);
                  }}
                  onBlur={() => {
                    onBlurEmail("email", email);
                    passwordRef.current.focus();
                  }}
                  icon={
                    <Image
                      source={require("../../images/ic_accept.png")}
                      style={styles.icon}
                    />
                  }
                  isShowIcon={isValidEmail}
                  keyboardType="email-address"
                  blurOnSubmit={true}
                  onSubmitEditing={() => {
                    // this.secondTextInput.focus();
                    //  alert(JSON.stringify(passwordRef));
                    passwordRef.current.focus();
                  }}
                  autoCapitalize="none"
                  returnKeyType="next"
                  autoFocus={false}
                  marginTop={24}
                  showRequired={true}
                  maxLength={emailMaxLength}
                />
                {errors.includes("email") && (
                  <Text
                    size={13}
                    lineHeight={16}
                    marginTop={4}
                    color={Colors.Red}
                  >
                    {errorMessages.email}
                  </Text>
                )}
                <InputApp
                  placeholder={locales_es.password}
                  testID="password"
                  forwardRef={passwordRef}
                  borderColor={
                    errors.includes("password")
                      ? theme.lineErrorColor
                      : theme.innearColor
                  }
                  title={locales_es.password}
                  value={password}
                  onInput={(value: string) => {
                    handleValidatorInputsTypes(
                      initForm,
                      value,
                      "",
                      setPassword,
                      "password",
                      0
                    );
                  }}
                  onChangeText={(value: string) => {
                    handleValidatorInputsTypes(
                      initForm,
                      value,
                      "",
                      setPassword,
                      "password",
                      0
                    );
                  }}
                  secureTextEntry={!visiblePassword}
                  marginTop={24}
                  icon={<Image source={eyeOnIcon} style={styles.icon} />}
                  isShowIcon
                  iconPress={onShowHidePassword}
                  blurOnSubmit={Platform.OS === "android"}
                  onSubmitEditing={() => {
                    // this.secondTextInput.focus();
                    //  alert(JSON.stringify(passwordRef));
                    Keyboard.dismiss();
                    onLogin();
                  }}
                  autoCapitalize="none"
                  returnKeyType="go"
                  autoCorrect={false}
                  showRequired={true}
                  maxLength={15}
                />
                {errors.includes("password") && (
                  <Text
                    size={13}
                    lineHeight={16}
                    marginTop={4}
                    color={Colors.Red}
                  >
                    {errorMessages.password}
                  </Text>
                )}
              </View>
              <ButtonLinear
                testID="login-submit"
                disabled={enableButton}
                white
                title={"Iniciar Sesión"}
                onPress={onLogin}
              />
              <TouchableOpacity
                style={styles.forgot}
                onPress={onForgotPassword}
              >
                <Text
                  type="H3"
                  color={Colors.GrayBlue}
                  style={{
                    ...styles.textUnderline,
                    ...(isDesktop ? styles.desktopForgot : {}),
                  }}
                >
                  {locales_es.forgotYourPassword}
                </Text>
              </TouchableOpacity>
            </div>
            <div style={styles.signUp}>
              <Text type="H3" color={Colors.GrayBlue}>
                <span style={isDesktop ? styles.desktopRegister : {}}>
                  {locales_es.login_page.do_not_have_account}{" "}
                </span>
                <Text
                  blueLight
                  type="H3"
                  color={Colors.BlueCrayola}
                  bold
                  onPress={onSignUp}
                >
                  <span style={isDesktop ? styles.desktopRegister : {}}>
                    {locales_es.login_page.register_link}
                  </span>
                </Text>
              </Text>
            </div>
          </div>
        )}
        {confirmEmail && (
          <div
            className="col-12 col-md-6 align-self-center "
            style={{ ...(isDesktop ? styles.desktopContent : {}), gap: 30 }}
          >
            <TouchableOpacity
              onPress={() => setConfirmEmail(false)}
              style={{
                marginVertical: 20,
                marginLeft: -2,
                height: 30,
                width: 30,
              }}
            >
              <Image
                source={SOURCE_ICON.back}
                style={{
                  height: 30,
                  width: 30,
                  cursor: "pointer",
                  marginBottom: 30,
                  marginRigth: 10,
                }}
                resizeMode="contain"
              />
            </TouchableOpacity>
            <Text center bold size={30}>
              Validación de tu e-mail
            </Text>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Text lineHeight={15} left size={16}>
              {locales_es.login_page.email_validation}
            </Text>
            <InputApp
              testID="email"
              borderColor={
                errors.includes("verficationEmail")
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              title={locales_es.email}
              placeholder={locales_es.email}
              value={verificationEmail}
              onChangeText={(value: string) => {
                handleValidatorInputsTypes(
                  initForm,
                  value,
                  "email",
                  setVerificationEmail,
                  "verficationEmail",
                  8
                );
                maxLengthEmail(value);
              }}
              onBlur={() => {
                onBlurEmail("verficationEmail", verificationEmail);
                emailRef.current.focus();
              }}
              icon={
                <Image
                  source={require("../../images/ic_accept.png")}
                  style={styles.icon}
                />
              }
              isShowIcon={isValidEmail}
              keyboardType="email-address"
              blurOnSubmit={true}
              onSubmitEditing={() => {
                // this.secondTextInput.focus();
                //  alert(JSON.stringify(passwordRef));
                emailRef.current.focus();
              }}
              autoCapitalize="none"
              returnKeyType="next"
              autoFocus={false}
              marginTop={24}
              showRequired={true}
              maxLength={emailMaxLength}
            />
            {errors.includes("verficationEmail") && (
              <Text size={13} lineHeight={16} marginTop={4} color={Colors.Red}>
                {errorMessages.email}
              </Text>
            )}
            <br />
            <br />
            <View
              style={{
                justifyContent: "center",
                alignText: "center",
                alignItems: "center",
              }}
            >
              <Text center lineHeight={15} bold size={16}>
                ¿No recibiste el e-mail?
              </Text>
              <br />
              <Text center lineHeight={15} size={16}>
                Podrás reenviar el e-mail de validación en{" "}
                {formatSeconds(counter)}
              </Text>
            </View>
            <br />
            <ButtonLinear
              testID="login-submit"
              white
              title={"Reenviar e-mail"}
              onPress={resendEmailVerification}
              style={{ marginTop: 24 }}
              disabled={
                isCounterActive ||
                errors.includes("verficationEmail") ||
                verificationEmail === ""
              }
            />
          </div>
        )}
      </div>
      <Footer className={helpers.isMobile() ? "" : "app-footer-auth"} />
    </div>
  );
});

export default Login;

const styles = StyleSheet.create({
  inputLogin: {
    marginTop: 16,
  },
  icon: {
    width: 24,
    height: 24,
  },
  margin24: {
    marginTop: 24,
  },
  forgot: {
    alignSelf: "center",
    marginTop: 32,
  },
  signUp: {
    alignSelf: "center",
    padding: "1rem",
    textAlign: "center",
  },
  textUnderline: {
    textAlign: "center",
    textDecorationLine: "underline",
  },
  textStyle: {
    fontSize: "2rem",
    padding: "1rem",
  },
  containerLogin: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  // Desktop styles
  desktopTitle: {
    fontSize: 28,
    fontWeight: "bold",
    paddingBottom: 0,
    textAlign: "left",
  },
  desktopContent: {
    margin: "auto",
    maxWidth: 550,
    gap: 4,
  },
  desktopForgot: {
    fontSize: 18,
  },
  desktopRegister: {
    fontSize: 18,
  },
});
