import React from 'react';
import Text from '../../components/Text';
import { Image } from 'react-native';
import Container from '../../components/Layout/Container';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import useEmailValidationSuccess from './shared/emailValidationSuccess';

const EmailValidationSuccess = () => {

  const {
    styles,
    continueFn,
    locales_es,
    iconSuccess,
    translations,
  } = useEmailValidationSuccess();

  return (
    <Container style={styles.container}>
      <Image
        source={iconSuccess}
        style={styles.successImage}
      />
      <Text size={20} lineHeight={24} bold>
        {locales_es.emailValidationSuccess.title}
      </Text>
      <Text size={15} lineHeight={24} center marginTop={16}>
        {locales_es.emailValidationSuccess.subtitle}
      </Text>
      <ButtonLinear
        white
        title={translations?.continue}
        onPress={continueFn}
        style={styles.buttonLinear}
      />
    </Container>
  );

};

export default EmailValidationSuccess;