import React, { useState, useCallback, useEffect, useRef } from "react";
import { Routes, Colors } from "../../../configs";
import { useHistory } from "react-router-dom";
import useModalWithKeyboard from "../../../hooks/useModalWithKeyboard";
import {
  EVENT_RELOAD_PATIENT,
  EVENT_RELOAD_PATIENTS,
  EVENT_SHOW_MODAL_INFO,
} from "../../../constants/Events";
import locales_es from "../../../locales/es";
import { InitForm, validateEmptyFields } from "../../../utils/validation/forms";
import { EventRegister } from "../../../modules/EventRegister";
import { showModalInfo } from "../../../modules/ViewHelpers";
import {
  api,
  appHelpers,
  auth,
  dateTimeService,
  helpers,
} from "../../../import";
import {
  FAKE_EMAIL_REGEX,
  INSURANCE_PERMISSION,
} from "../../../models/constants";
import TranslationService from "../../../locales";
import { useTheme } from "../../../configs/Theme";
import { categoryList } from "../../../type/category";

let timeoutId = null;

function debounce(func, delay) {
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

const useAddPatientPage = () => {
  const history = useHistory();
  const params: any = history.location.state;
  const [errors, setErrors] = useState([]);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const [modalMedicalInusrance, setModalMedicalInusrance] = useState(false);
  const [insurancePermission, setInsurancePermission] = useState(false);
  const [modalEditMedicalInusrance, setModalEditMedicalInusrance] =
    useState(false);
  const [auxIndex, setAuxIndex] = useState(null);
  const translationService = new TranslationService();
  const [translations, setTranslations] = useState({});

  const [patientId, setPatientId] = useState(params ? params.patientId : null);

  const [externalIdParam] = useState(params ? params.externalIdParam : null);

  const [patientData, setPatientData] = useState(
    params ? params.patientData : null
  );

  const [patientMedicalInsuranceId, setPatientMedicalInsuranceId] =
    useState<any>(null);

  const [medicalInsuranceSelected, setMedicalInsuranceSelected] =
    useState<any>(null);

  const [countryId, setCountryId] = useState(null); // null by now. Maybe 1 for "Argentina"?
  const [profileImage, setProfileImage] = useState("");
  const [images, setImages] = useState([]);

  const { historyBackSteps, screenTitle }: any = params ? params : {};

  const [cropping, setCropping] = useState(false);
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [identification, setIdentification] = useState("");

  const [gendersList, setGendersList] = useState(null);

  const [identificationType, setIdentificationType] = useState(null);
  const [identificationTypeId, setIdentificationTypeId] = useState(null);
  const [identificationTypesList, setIdentificationTypesList] = useState(null);
  const [countries, setCountries] = useState([]);
  const [codeArea, setCodeArea] = useState(null);

  const [email, setEmail] = useState("");
  const [medicalInsuranceList, setMedicalInsuranceList] = useState<any>(null);
  const [medicalInsurancePatientList, setMedicalInsurancePatientList] =
    useState<any>([]);
  const [medicalInsurance, setMedicalInsurance] = useState<any>(null);
  const [medicalInsurancePlan, setMedicalInsurancePlan] = useState("");
  const [medicalInsuranceNumber, setMedicalInsuranceNumber] = useState("");
  const [medicalInsuranceDniTitular, setMedicalInsuranceDniTitular] =
    useState("");
  const [showPreSend, setShowPreSend] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [debounceLoading, setDebounceLoading] = useState(false);

  const [comment, setComment] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState<{
    id?: number | null;
    name?: string | null;
    code?: string | null;
    icon?: any;
  }>({ id: 3, title: null, icon: null });

  // External Patients
  const [externalId, setExternalId] = useState(null);
  const [clinicIdParam] = useState(params ? params.clinicIdParam : null);

  const [minDocLength, setMinDocLength] = useState(6);
  const [maxDocLength, setMaxDocLength] = useState(8);

  const [messageModal, setMessageModal] = useState("");
  const [typeAction, setTypeAction] = useState("");
  const [showInfoModal, setShowInfoModal] = useState(false);

  const { theme } = useTheme();
  const inputRef: any = useRef();

  const onChangeMedicalInsurance = (item: categoryList) => {


    setMedicalInsurance(item);
    if (item.name === "Sin cobertura") {
      let getErrors = errors.filter(
        (error: any) => error !== "medicalInsuranceNumber"
      );
      setErrors(getErrors);
      let validateErros = validateEmptyFields({
        ...initValues,
      });


      if (getErrors.length > 0 || validateErros.length > 0) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    } else {
      setMedicalInsurancePlan("");
      setMedicalInsuranceNumber("");
      setEnableButton(true);
    }
    closeMedicalInsuranceModal();
  };

  const [enableButton, setEnableButton] = useState(true);

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    lastname: "",
    identification: "",
    email: "",

    medicalInsuranceNumber: "",
    phoneNumber: "",
    required: "El Campo es obligatorio",
  });

  var initValues: any = {
    name,
    lastname,
    identification,
  };

  var initValuesAdittional: any = {
    ...initValues,
    medicalInsuranceNumber,
  };

  var initForm: InitForm = {
    setErrors,
    errors,
    setErrorMessages,
    errorMessages,
    setEnableButton,
    initValues,
  };

  useEffect(() => {
    if (errors.length > 0 || debounceLoading) {
      setEnableButton(true);
    }else{
      setEnableButton(false);
    }
  }, [errors]);

  // modal para verificacar si el paciente tiene cobertura medica al momento de guardar/actualizar
  const onPreSend = () => {
    const isMedicalInsuranceNone = medicalInsurancePatientList.filter(
      (item) => item.medical_insurance_id === 1
    );
    const showModalPreSend =
      ((isMedicalInsuranceNone.length >= 1 &&
        medicalInsurancePatientList.length === 1) ||
        medicalInsurancePatientList.length === 0) ??
      false;
    if (showModalPreSend && insurancePermission) {
      setShowPreSend(true);
      return;
    } else {
      onSend();
    }
  };

  const validateSmtpDomain = useCallback(
    (email: string) => {
      if (errors.includes("email") || debounceLoading) return;

      const atIndex = email.indexOf("@");
      const lastDotIndex = email.lastIndexOf(".");

      // Verifica si hay al menos dos caracteres después del último punto
      if (
        atIndex > -1 &&
        lastDotIndex > atIndex &&
        email.length > lastDotIndex + 2
      ) {
        const objtData = { email: email };
        setDebounceLoading(true);
        api
          .validateSmtpDomain(objtData)
          .then(() => {
            setDebounceLoading(false);
            setErrors((prevErrors) =>
              prevErrors.filter((error) => error !== "email")
            );
          })
          .catch((error) => {
            setDebounceLoading(false);
            setErrors((prevErrors) => [...prevErrors, "email"]);
            setErrorMessages((prevErrorMessages) => ({
              ...prevErrorMessages,
              email: `${helpers.getErrorMsg(error)}`,
            }));
          })
          .finally(() => {
            setDebounceLoading(false);
          });
      }
    },
    [errors, debounceLoading]
  );

  const debouncedValidateSmtpDomain = useCallback(
    debounce(validateSmtpDomain, 1000),
    [validateSmtpDomain]
  );

  useEffect(() => {
    if (email && !debounceLoading) {
      debouncedValidateSmtpDomain(email);
    }
  }, [email]);

  // modal para verificacar si el medico desea guardar el paciente sin cobertura medica
  const onAceptPreSend = () => {
    setShowPreSend(false);

    const insuranceNone = medicalInsuranceList.find(
      (item: any) => item.name === ("Sin cobertura" || "Ninguna")
    );

    const insuEmpty = {
      medical_insurance_id: insuranceNone.id,
      medical_insurance_plan: "",
      medical_insurance_number: "",
      medical_insurance_name: insuranceNone.name,
      medical_insurance_dni_titular: "",
      medical_insurance_selected: 1,
    };

    let medicalInsurances = [...medicalInsurancePatientList, insuEmpty].sort(
      (
        a: { medical_insurance_name: string },
        b: { medical_insurance_name: string }
      ) => {
        if (
          a.medical_insurance_name === "Ninguna" ||
          a.medical_insurance_name === "Sin cobertura"
        )
          return 2;
        if (a.medical_insurance_name < b.medical_insurance_name) {
          return -1;
        }
        if (a.medical_insurance_name > b.medical_insurance_name) {
          return 1;
        }
        return 0;
      }
    );

    setMedicalInsurancePatientList(medicalInsurances);
    onSend();
  };
  //enviando datos del paciente
  const onSend = () => {
    let values = initValues;
    if (
      medicalInsurance &&
      !medicalInsurance.is_none &&
      medicalInsurance.name !== "Sin cobertura"
    ) {
      values = initValuesAdittional;
    }
    const errors = validateEmptyFields({
      ...values,
    });

    const identificationValidation = appHelpers.validateIdentification(
      countryId,
      identification,
      identificationTypeId
    );

    if (identificationValidation !== true) {
      showModalInfo(
        identificationValidation.title,
        identificationValidation.message
      );
      return;
    }

    if (errors.length) {
      setErrors(errors);
      showModalInfo(
        locales_es.infoModal.title,
        locales_es.errorModal.checkData
      );
    } else {
      // setLoading(true);
      const objData = {
        name,
        lastname,
        identification_type_id: identificationTypeId,
        identification,
        gender_id: gender.id,
        birth_date: birthDate,
        country_id: codeArea.id,
        prefix_cell_phone: codeArea.prefix_cell_phone,
        cell_phone: phoneNumber,
        email,
        medicals_insurances:
          !patientId && medicalInsurancePatientList.length > 0
            ? medicalInsurancePatientList
            : [],
        comment,
        external_id: externalId,
        clinic_id: clinicIdParam,
      };

      //verificamos que medicalInsurancePatientList tenga la cobertura Ninguna

      const insuranceName = medicalInsurancePatientList.filter(
        (item: any) =>
          item.medical_insurance_name === ("Sin cobertura" || "Ninguna")
      );

      if (insuranceName.length === 0) {
        const insuNone = medicalInsuranceList.find(
          (item: any) => item.name === ("Sin cobertura" || "Ninguna")
        );
        //si no contiene la cobertura Ninguna, la agregamos
        const insuEmpty = {
          medical_insurance_id: insuNone.id,
          medical_insurance_plan: "",
          medical_insurance_number: "",
          medical_insurance_name: insuNone.name,
          medical_insurance_dni_titular: "",
          medical_insurance_selected: 1,
        };

        let medicalInsurances = [
          ...medicalInsurancePatientList,
          insuEmpty,
        ].sort((a, b) => {
          if (a.medical_insurance_id === 1) return 1;
          if (b.medical_insurance_id === 1) return -1;
          return 0;
        });

        setMedicalInsurancePatientList(medicalInsurances);

        objData.medicals_insurances = medicalInsurancePatientList;
      }

      if (patientId && !externalIdParam) {
        api
          .putPatient(patientId, objData)
          .then((res: any) => {
            EventRegister.emit(EVENT_RELOAD_PATIENT, {});
            patientActionResolve(res);
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err)
            );
            setLoading(false);
          });
      } else {
        api
          .postPatient(objData)
          .then((res: any) => {
            patientActionResolve(res);
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err)
            );
            setLoading(false);
          });
      }
    }
  };

  const patientActionResolve = (res: any) => {
    const promises = [];
    const regexUrl =
      /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/;
    if (profileImage && profileImage.uri && !regexUrl.test(profileImage.uri)) {
      promises.push(api.postPatientProfileImage(res.data.id, profileImage.uri));
    }

    if (promises.length) {
      setLoadingText(locales_es.uploadingImage);
      Promise.all(promises)
        .then(() => {
          goToNextStep(res);
        })
        .catch((err: any) => {
          showModalInfo(locales_es.infoModal.title, helpers.getErrorMsg(err));
          setLoading(false);
          setLoadingText("");
        });
    } else {
      goToNextStep(res);
    }
  };

  const goToNextStep = (res: any) => {
    EventRegister.emit(EVENT_RELOAD_PATIENTS, {});
    setLoading(false);
    setLoadingText("");
    history.replace(Routes.PatientProfile, {
      patientId: res.data.id,
      historyBackSteps,
    });
  };

  const {
    open: openMedicalInsuranceModal,
    close: closeMedicalInsuranceModal,
    visible: visibleMedicalInsuranceModal,
  } = useModalWithKeyboard();

  const {
    visible: visibleMenuOption,
    open: openMenuOption,
    close: closeMenuOption,
  } = useModalWithKeyboard();

  const {
    visible: visibleCountries,
    open: openCountries,
    close: closeCountries,
  } = useModalWithKeyboard();

  const handleSelectIdentificationTypeMenuOption = React.useCallback(
    (item) => {
      setIdentificationType(item.name);
      setIdentificationTypeId(item.id);
      setMinDocLength(item.min);
      setMaxDocLength(item.max);
      // setIdentification(patientData && patientData.identification ? patientData.identification :'');
      closeMenuOption();
    },
    [closeMenuOption, history]
  );

  const onChangeCode = (item: TcodeArea) => {
    setCodeArea(item);
    closeCountries();
  };

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter((item) => item !== inputStr));
  };

  const lastnameRef = React.createRef();
  const identificationRef = React.createRef();
  const dateRef = React.createRef();
  const phoneNumberRef = React.createRef();
  const emailRef = React.createRef();
  const medicalInsuranceNumberRef = React.createRef();
  const medicalInsuranceIdNumberRef = React.createRef();

  useEffect(() => {
    if (countryId) {
      api
        .getIdentificationTypesByCountryId(countryId)
        .then((res: any) => {
          setIdentificationTypesList(res.data);
          if (!patientId) {
            handleSelectIdentificationTypeMenuOption(res.data[0]);
          }
          // handleSelectIdentificationTypeMenuOption(res.data[0]);
        })
        .catch((err: any) => {
          showModalInfo(
            locales_es.errorModal.title,
            helpers.getErrorMsg(err.message)
          );
        });
      api
        .getCountries()
        .then((res: any) => {
          setCountries(res.data);
          if (!patientId) {
            // setCodeArea(res.data[0]);
            setCodeArea(
              res.data.filter((country: any) => country.id === countryId)[0]
            );
          }
        })
        .catch((err: any) => {
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.errorModal.title,
            message: helpers.getErrorMsg(err),
          });
        });
      api
        .getMedicalInsurancesByCountryId(countryId)
        .then((res: any) => {
          const insurancesList = res.data;

          setMedicalInsuranceList(insurancesList);
          if (patientData && patientData.medical_insurance_id) {
            const insurance = insurancesList.filter(
              (i: any) =>
                Number(i.id) === Number(patientData.medical_insurance_id)
            )[0];
            setMedicalInsurance(insurance);
          } else if (externalIdParam && patientData.medical_insurance) {
            setMedicalInsurance(patientData.medical_insurance || null);
          } else if (!patientId) {
            setMedicalInsurance(res.data[0]);
          }
        })
        .catch((err: any) => {
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.errorModal.title,
            message: helpers.getErrorMsg(err),
          });
        });
    }

    translationService.translate().then((translation) => {
      setTranslations(translation);
    });
  }, [countryId]);

  // COMPONENT-DID-MOUNT AND LOAD()
  useEffect(() => {
    auth.getPermissions().then((permissions: any) => {
      setInsurancePermission(
        permissions.some((p) => p.tag === INSURANCE_PERMISSION)
      );
    });
    auth
      .getLocalUserData()
      .then((res: any) => {
        setCountryId(res.user.country_id);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
    api
      .getGenders()
      .then((res: any) => {
        setGendersList(res.data);
      })
      .catch((err: any) => {
        showModalInfo(
          locales_es.errorModal.title,
          helpers.getErrorMsg(err.message)
        );
      });

    if (patientData) {
      setName(patientData && patientData.name ? patientData.name : "");
      setLastname(
        patientData && patientData.lastname ? patientData.lastname : ""
      );

      if (externalIdParam) {
        setIdentification(
          patientData && patientData.identification
            ? patientData.identification
            : ""
        );
        setGender({ id: patientData.gender_id });
        if (patientData && patientData.birth_date) {
          const parsedDate = dateTimeService.parseAPIStringToDate(
            patientData.birth_date
          );
          setSelectedDate(parsedDate);
          setBirthDate(patientData.birth_date);
        }
        setEmail(patientData && patientData.email ? patientData.email : "");
        setEnableButton(false);
      }
    }

    if (patientId) {
      setLoading(true);
      setLoadingText("Cargando paciente...");
      api
        .getPatient(patientId)
        .then((res: any) => {
          // setUserData(res.data);
          const userData = res.data;
          if (userData) {
            setUserData(userData);
            handleSelectIdentificationTypeMenuOption(
              userData.identification_type
            );
            setIdentification(userData.identification);
          }
          setLoading(false);
          setLoadingText("");
        })
        .catch((err: any) => {
          helpers.processError(err);
          setLoading(false);
          setLoadingText("");
        });
      api
        .getPatientImages(patientId)
        .then((res: any) => {
          setImages(res.data);
        })
        .catch((err: any) => {
          helpers.processError(err);
        });

      api
        .getPatientMedicalInsurances(patientId)
        .then((res: any) => {
          let data = res.data.map((item: any) => {
            return {
              patients_medical_insurances_id: item.id,
              medical_insurance_id: item.medical_insurance_id,
              medical_insurance_name: item.medical_insurance.name,
              medical_insurance_plan: item.medical_insurance_plan,
              medical_insurance_number: item.medical_insurance_number,
              medical_insurance_dni_titular: item.medical_insurance_dni_titular,
              medical_insurance_selected: item.medical_insurance_selected,
            };
          });

          data = data.sort((a: any, b: any) => {
            if (a.medical_insurance_id === 1) return 1;
            if (b.medical_insurance_id === 1) return -1;
            return 0;
          });

          setMedicalInsurancePatientList(data);
        })
        .catch((err: any) => {
          helpers.processError(err);
        });
    }

    if (!patientId) {
      let data = [];

      if (externalIdParam && patientData.medical_insurance) {
        data.push({
          medical_insurance_id: patientData.medical_insurance.id,
          medical_insurance_name: patientData.medical_insurance.name,
          medical_insurance_plan: patientData.medical_insurance_plan,
          medical_insurance_number: patientData.medical_insurance_number,
          medical_insurance_dni_titular: null,
          medical_insurance_selected: 1,
        });
      }

      data.push({
        medical_insurance_id: 1,
        medical_insurance_name: "Sin cobertura",
        medical_insurance_plan: null,
        medical_insurance_number: null,
        medical_insurance_dni_titular: null,
        medical_insurance_selected: 0,
      });

      setMedicalInsurancePatientList(data);
    }
  }, [load]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_PATIENT,
      (data: any) => {
        api
          .getPatientMedicalInsurances(patientId)
          .then((res: any) => {
            let data = res.data.map((item: any) => {
              return {
                patients_medical_insurances_id: item.id,
                medical_insurance_id: item.medical_insurance_id,
                medical_insurance_name: item.medical_insurance.name,
                medical_insurance_plan: item.medical_insurance_plan,
                medical_insurance_number: item.medical_insurance_number,
                medical_insurance_dni_titular:
                  item.medical_insurance_dni_titular,
                medical_insurance_selected: item.medical_insurance_selected,
              };
            });

            data = data.sort((a: any, b: any) => {
              if (a.medical_insurance_id === 1) return 1;
              if (b.medical_insurance_id === 1) return -1;
              return 0;
            });

            setMedicalInsurancePatientList(data);
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err)
            );
          });
      }
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  const setUserData = (userData: any) => {
    setProfileImage({ uri: userData.full_profile_image });
    setName(userData.name);
    setLastname(userData.lastname);
    setIdentificationTypeId(userData.identification_type.id); // WARN: duplicated id and name
    setIdentificationType(userData.identification_type.name); // WARN: duplicated id and name
    setIdentification(userData.identification);
    setGender({ id: userData.gender_id });
    const parsedDate = dateTimeService.parseAPIStringToDate(
      userData.birth_date
    );
    setSelectedDate(parsedDate);
    setBirthDate(userData.birth_date);
    setCodeArea(userData.country);
    setPhoneNumber(userData.cell_phone);
    setEmail(FAKE_EMAIL_REGEX.test(userData.email) ? "" : userData.email);
    // setMedicalInsurance(userData.medical_insurance);
    // setMedicalInsurancePlan(userData.medical_insurance_plan);
    // setMedicalInsuranceNumber(userData.medical_insurance_number);
    setComment(userData.comment);
    setMinDocLength(userData.identification_type.min);
    setMaxDocLength(userData.identification_type.max);
    setEnableButton(false);
  };

  const onSaveCrop = (image: any) => {
    setCropping(false);
    setProfileImage({ uri: image });
  };

  // BirthDate logics
  const removeFromErrors = (string: any) => {
    let errors = JSON.parse(JSON.stringify(errors));
    errors = errors.filter((item) => item !== string);
    setErrors(errors);
  };

  const handleDateChange = () => (value: any) => {
    setSelectedDate(value);
    const parsedDate = dateTimeService.parseDateToAPIString(value);
    setBirthDate(parsedDate);
  };

  //Añadir cobertura medica
  const onAddMedicalInsurance = () => {
    const objetData = {
      medical_insurance_id: medicalInsurance.id,
      medical_insurance_plan: medicalInsurancePlan,
      medical_insurance_number: medicalInsuranceNumber,
      medical_insurance_dni_titular: medicalInsuranceDniTitular,
      medical_insurance_name: medicalInsurance.name,
      medical_insurance_selected: 1,
    };

    //aqui validamos que no se repita el nombre de la cobertura
    const insuranceName = medicalInsurancePatientList.filter(
      (item: any) => item.medical_insurance_name === medicalInsurance.name
    );

    if (insuranceName.length > 0) {
      showModalInfo(
        "Cobertura Médica",
        "Ya existe la cobertura médica registrada para el paciente."
      );
      setModalMedicalInusrance(false);
      setMedicalInsurance(null);
      setMedicalInsurancePlan("");
      setMedicalInsuranceNumber("");
      setMedicalInsuranceDniTitular("");
      return;
    }

    //validamos si la el nombre de la coberutra es OSDE que su plan sea de 11 digitos

    if (medicalInsurance.id === 206 || medicalInsurance.id === 149) {
      //validamos que el plan de OSDE tenga 11 digitos

      if (medicalInsuranceNumber.length !== 11) {
        setModalMedicalInusrance(false);
        setMessageModal(
          `El plan de ${
            medicalInsurance.id === 206 ? "OSDE" : "LUIS PASTEUR"
          } debe tener 11 dígitos.`
        );
        setTypeAction("add");
        setShowInfoModal(true);
        // showModalInfo(
        //   locales_es.errorModal.title,
        //   `El plan de ${
        //     medicalInsurance.id === 206 ? "OSDE" : "LUIS PASTEUR"
        //   } debe tener 11 dígitos`
        // );
        return;
      }

      //validamos que el plan de OSDE no tenga letras ni espacios
      if (medicalInsuranceNumber.match(/^[0-9]+$/) === null) {
        setModalMedicalInusrance(false);
        setMessageModal(
          `El plan de ${
            medicalInsurance.id === 206 ? "OSDE" : "LUIS PASTEUR"
          }, no se aceptan letras ni espacios.`
        );
        setTypeAction("add");
        setShowInfoModal(true);
        // showModalInfo(
        //   locales_es.errorModal.title,
        //   `El plan de ${
        //     medicalInsurance.id === 206 ? "OSDE" : "LUIS PASTEUR"
        //   }, no se aceptan letras ni espacios.`
        // );
        return;
      }
    }

    if (patientId) {
      setLoading(true);
      setModalMedicalInusrance(false);
      api
        .postPatientMedicalInsurance(patientId, objetData)
        .then((res: any) => {
          if (res.status === true) {
            setLoading(false);
            EventRegister.emit(EVENT_RELOAD_PATIENT, {});
            setMedicalInsurance(null);
            setMedicalInsurancePlan("");
            setMedicalInsuranceNumber("");
            setMedicalInsuranceDniTitular("");
          } else {
            setModalMedicalInusrance(false);
            setLoading(false);
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(res.message)
            );
          }
        })
        .catch((err: any) => {
          console.error(err);
          setLoading(false);
          helpers.processError(err);
        });
    } else {
      setModalMedicalInusrance(false);
      let medicalInsurances = [...medicalInsurancePatientList, objetData]
        .map((insurance) => {
          return {
            ...insurance,
            medical_insurance_selected:
              objetData.medical_insurance_id === insurance.medical_insurance_id,
          };
        })
        .sort((a: any, b: any) => {
          if (a.medical_insurance_id === 1) return 1;
          if (b.medical_insurance_id === 1) return -1;
          return 0;
        });
      setMedicalInsurancePatientList(medicalInsurances);
      setMedicalInsurance(null);
      setMedicalInsurancePlan("");
      setMedicalInsuranceNumber("");
      setMedicalInsuranceDniTitular("");
    }
  };

  //Editar cobertura medica
  const onEditMedicalInsurance = () => {
    setModalEditMedicalInusrance(false);
    const objetData = {
      medical_insurance_id: medicalInsurance.id,
      medical_insurance_patient_id: patientMedicalInsuranceId
        ? patientMedicalInsuranceId
        : null,
      medical_insurance_plan: medicalInsurancePlan,
      medical_insurance_number: medicalInsuranceNumber,
      medical_insurance_name: medicalInsurance.name,
      medical_insurance_selected: medicalInsuranceSelected
        ? medicalInsuranceSelected
        : 0,
      medical_insurance_dni_titular: medicalInsuranceDniTitular,
    };

    //validamos si la el nombre de la coberutra es OSDE que su plan sea de 11 digitos

    if (medicalInsurance.id === 206 || medicalInsurance.id === 149) {
      //validamos que el plan de OSDE tenga 11 digitos
      if (medicalInsuranceNumber.length !== 11) {
        setModalMedicalInusrance(false);
        setMessageModal(
          `El plan de ${
            medicalInsurance.id === 206 ? "OSDE" : "LUIS PASTEUR"
          } debe tener 11 dígitos.`
        );
        setTypeAction("edit");
        setShowInfoModal(true);
        return;
      }

      //validamos que el plan de OSDE no tenga letras ni espacios
      if (medicalInsuranceNumber.match(/^[0-9]+$/) === null) {
        setModalMedicalInusrance(false);
        setMessageModal(
          `El plan de ${
            medicalInsurance.id === 206 ? "OSDE" : "LUIS PASTEUR"
          }, no se aceptan letras ni espacios.`
        );
        setTypeAction("edit");
        setShowInfoModal(true);
        return;
      }
    }

    const insuranceName = medicalInsurancePatientList.filter(
      (insurance: any, index: any) =>
        insurance.medical_insurance_id === medicalInsurance.id &&
        index !== auxIndex
    );

    if (insuranceName.length > 0) {
      setModalEditMedicalInusrance(false);
      showModalInfo(
        "Cobertura Médica",
        "Ya existe la cobertura médica registrada para el paciente."
      );
      return;
    }

    if (patientId) {
      setLoading(true);
      api
        .putPatientMedicalInsurance(
          patientId,
          patientMedicalInsuranceId,
          objetData
        )
        .then((res: any) => {
          if (res.status === true) {
            setModalEditMedicalInusrance(false);
            setLoading(false);
            setMedicalInsurance(null);
            setMedicalInsurancePlan("");
            setMedicalInsuranceNumber("");
            setMedicalInsuranceDniTitular("");
            EventRegister.emit(EVENT_RELOAD_PATIENT, {});
          } else {
            setLoading(false);
            setModalEditMedicalInusrance(false);
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(res.message)
            );
          }
        })
        .catch((err: any) => {
          console.error(err);
          setLoading(false);
          helpers.processError(err);
        });
    } else {
      setModalEditMedicalInusrance(false);
      medicalInsurancePatientList[auxIndex] = objetData;
      setMedicalInsurancePatientList(medicalInsurancePatientList);
      setMedicalInsurance(null);
      setMedicalInsurancePlan("");
      setMedicalInsuranceNumber("");
      setMedicalInsuranceDniTitular("");
    }
  };

  //Eliminar cobertura medica
  const onRemovePatientMedicalInsurance = (medicalInsurance: any) => {
    if (patientId) {
      setLoading(true);
      api
        .deletePatientMedicalInsurance(
          patientId,
          medicalInsurance.patients_medical_insurances_id
        )
        .then((res: any) => {
          if (res.status === true) {
            setLoading(false);
            EventRegister.emit(EVENT_RELOAD_PATIENT, {});
          } else {
            setLoading(false);
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(res.message)
            );
          }
        })
        .catch((err: any) => {
          setLoading(false);
          helpers.processError(err);
        });
    } else {
      const medicalInsuranceSelected = medicalInsurancePatientList.filter(
        (item: any) =>
          item.medical_insurance_id !== medicalInsurance.medical_insurance_id
      );
      setMedicalInsurancePatientList(medicalInsuranceSelected);
    }
  };

  const onMedicalInsuranceSelect = (index: any) => {
    const medicalInsuranceSelected = medicalInsurancePatientList.map(
      (item, ind) => {
        if (ind === index) {
          item.medical_insurance_selected = true;
        } else {
          item.medical_insurance_selected = false;
        }
        return item;
      }
    );

    setMedicalInsurancePatientList(medicalInsuranceSelected);
  };

  return {
    debouncedValidateSmtpDomain,
    handleDateChange,
    removeFromErrors,
    onSaveCrop,
    lastnameRef,
    identificationRef,
    dateRef,
    phoneNumberRef,
    emailRef,
    medicalInsuranceNumberRef,
    medicalInsuranceIdNumberRef,
    onBlur,
    onChangeCode,
    visibleCountries,
    visibleMedicalInsuranceModal,
    minDocLength,
    maxDocLength,
    enableButton,
    setEnableButton,
    errorMessages,
    setErrorMessages,
    externalIdParam,
    initValues,
    initForm,
    initValuesAdittional,
    openCountries,
    visibleMenuOption,
    openMenuOption,
    openMedicalInsuranceModal,
    theme,
    inputRef,
    selectedDate,
    medicalInsuranceList,
    medicalInsurancePatientList,
    countries,
    identificationTypesList,
    identificationType,
    cropping,
    gendersList,
    images,
    setPatientData,
    patientData,
    setPatientId,
    patientId,
    translations,
    setTranslations,
    loading,
    loadingText,
    setLoad,
    setLoading,
    setLoadingText,
    onSend,
    onPreSend,
    onAceptPreSend,
    onChangeMedicalInsurance,
    errors,
    setErrors,
    historyBackSteps,
    screenTitle,
    profileImage,
    setCropping,
    name,
    setName,
    lastname,
    setLastname,
    setBirthDate,
    birthDate,
    setCodeArea,
    codeArea,
    setEmail,
    email,
    setIdentification,
    identification,
    gender,
    setGender,
    phoneNumber,
    setPhoneNumber,
    medicalInsurance,
    setMedicalInsurance,
    medicalInsurancePlan,
    setMedicalInsurancePlan,
    setMedicalInsuranceDniTitular,
    medicalInsuranceDniTitular,
    medicalInsuranceNumber,
    setMedicalInsuranceNumber,
    closeMenuOption,
    handleSelectIdentificationTypeMenuOption,
    closeCountries,
    closeMedicalInsuranceModal,
    comment,
    setComment,
    setMedicalInsurancePatientList,
    modalMedicalInusrance,
    setModalMedicalInusrance,
    modalEditMedicalInusrance,
    setModalEditMedicalInusrance,
    auxIndex,
    setAuxIndex,
    showPreSend,
    setShowPreSend,
    messageModal,
    setMessageModal,
    typeAction,
    setTypeAction,
    showInfoModal,
    setShowInfoModal,
    setPatientMedicalInsuranceId,
    setMedicalInsuranceSelected,
    onAddMedicalInsurance,
    onEditMedicalInsurance,
    onMedicalInsuranceSelect,
    onRemovePatientMedicalInsurance,
    insurancePermission,
  };
};

export default useAddPatientPage;
