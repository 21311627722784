import {
  userDataLocalStorage,
  userAccessToken,
  HREF_PAGE_LOGIN,
  configDataLocalStorage,
  userTemporalDataPostRegister,
  HREF_PAGE_ONBOARDING,
} from "./../models/constants";
import { headerNavId, setNavLinksEvent } from "../models/constants";
import "./../modules/apiService";
import APIService from "./apiService";
import CoachMarksService from "./coachmarks";
import {EventRegister} from "./EventRegister";
import {EVENT_FORBIDDEN, EVENT_USER_CHANGED} from "../constants/Events";

class AuthService {

  constructor() {
    this.api = new APIService();
    this.coachMarksService = new CoachMarksService();
  }

  // Helper to get GET parameters from the URL
  getUrlVars() {
    if (!window) {
      return;
    }
    var vars = {};
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  }

  checkExternalAccessToken() {
    const accessToken = this.getUrlVars()[userAccessToken];
    if (accessToken) {
      this.saveAccessToken(accessToken); // for API Service Interceptor
      // Get user data
      this.getRemoteUserData()
        .then((res) => {
          res.access_token = accessToken;
          this.saveUserData(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async loginFromRegister(res) {
    this.saveUserData(res);
    this.coachMarksService.setInitialData();
    // this.sendOneSignalTags(res.data.user);
  }

  async login(userData) {
    const loginResponse = await this.api
      .login(userData)
      .then((res) => {
        this.saveUserData(res.data);
        this.setHeaderInOrderOfUserStatus(); // Para habilitar menúes. Deshabilitado por ahora
        return res.data;
      })
      .catch((err) => {
        throw new Error(err);
      });

    return loginResponse;
  }

  async register(data, saveData = true) {
    const registerResponse = await this.api
      .register(data)
      .then((res) => {
        let temporalData = {
          email: data.email,
          password: data.password,
          password_confirmation: data.password_confirmation,
          cell_phone: data.cell_phone,
          prefix_cell_phone: data.prefix_cell_phone,
          country_id: data.country_id,
        };
        if (saveData) {
          sessionStorage.setItem(
            userTemporalDataPostRegister,
            JSON.stringify(temporalData)
          );
          this.saveUserData(res.data);
          this.setHeaderInOrderOfUserStatus(); // Para habilitar menúes. Deshabilitado por ahora
        }
        return res.data;
      })
      .catch((err) => {
        throw new Error(err);
      });

    return registerResponse;
  }

  async updateUser(userData) {
    const updateResponse = await this.api
      .updateUser(userData)
      .then((res) => {
        // this.saveUserData(res.data);
        // this.setHeaderInOrderOfUserStatus(); // Para habilitar menúes. Deshabilitado por ahora
        return res;
      })
      .catch((err) => {
        throw err;
      });

    return updateResponse;
  }

  async recovery(email) {
    const recoveryResponse = await this.api
      .passwordRequest(email)
      .catch((err) => {
        throw new Error(err);
      });

    return recoveryResponse;
  }

  changePassword(obj) {
    return new Promise((resolve, reject) => {
      this.api
        .updatePasword(obj)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  logout(redirect, unauthorized = false, toOnboarding = false) {
    localStorage.clear();
    this.setHeaderInOrderOfUserStatus();
    EventRegister.emit(EVENT_USER_CHANGED, {});
    if (redirect) {
      if (unauthorized) {
        this.setUnauthorized();
      }
      window.location.href = `${(!toOnboarding) ? HREF_PAGE_LOGIN : HREF_PAGE_ONBOARDING}`;
    }
  }

  setUnauthorized() {
    localStorage.setItem('unauthorized', '1');
  }

  removeUnauthorized() {
    localStorage.removeItem('unauthorized');
  }

  isUnauthorized() {
    return localStorage.getItem('unauthorized') === '1';
  }
  
  closeAllSessions() {
    return new Promise(async (resolve) => {
      try {
        const resClose = await this.api.closeAllSessions();
        resolve(resClose);  
      } catch (err) {
        resolve(false);
      }
    })
  }

  saveAccessToken(accessToken) {
    // Save for others views. This is a possible entry view:
    //$localStorage.access_token = this.getUrlVars()[userAccessToken];
    localStorage.setItem(
      userDataLocalStorage,
      JSON.stringify({
        [userAccessToken]: accessToken,
      })
    );
  }

  saveUserData(userData) {
    localStorage.setItem(userDataLocalStorage, JSON.stringify(userData));
    EventRegister.emit(EVENT_USER_CHANGED, {});
  }

  getLocalUserData = () => {
    return new Promise((resolve, reject) => {
      try {
        const value = localStorage.getItem(userDataLocalStorage);
        resolve(JSON.parse(value));
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  getPermissions = () => {
    return new Promise((resolve, reject) => {
      if (this.isLoggedUser()) {
        this.api.getPermissionsMe().then((res) => {
          resolve(res);
        }).catch((err) => {
          resolve(false);
        })
      }
      return false;
    })
  }

  getAndSaveRemoteConfigData() {
    return new Promise((resolve, reject) => {
      this.api
        .getConfig()
        .then((res) => {
          localStorage.setItem(
            configDataLocalStorage,
            JSON.stringify(res.data)
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getRemoteUserData() {
    return new Promise((resolve, reject) => {
      this.api
        .getUserMe()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          const publicRoute =
            (window.location.pathname === "/login" ||
              window.location.pathname === "/register" ||
              window.location.pathname === "/forgot-password" ||
              window.location.pathname === "/reset-password" ||
              window.location.pathname === "/services") ??
            false;
          if (err?.response?.status == 401 && !publicRoute) {
            EventRegister.emit(EVENT_FORBIDDEN, {});
            return;
          }
          if (publicRoute) return;
          reject(err);
        });
    });
  }

  getLocalUserType() {
    const ls = JSON.parse(localStorage.getItem(userDataLocalStorage));
    return ls && ls.user && ls.user.user_type ? ls.user.user_type : undefined;
  }

  getUserData() {
    return JSON.parse(localStorage.getItem(userDataLocalStorage));
  }

  getAccessToken() {
    const userData = JSON.parse(localStorage.getItem(userDataLocalStorage));
    return userData && userData[userAccessToken]
      ? userData[userAccessToken]
      : null;
  }

  isLoggedUser() {
    return !!localStorage.getItem(userDataLocalStorage);
  }

  checkLoginStatusAndDoSomethingOrDefault(doSomething, _default) {
    const userData = JSON.parse(localStorage.getItem(userDataLocalStorage));
    userData && userData.token ? doSomething() : _default();
  }

  setHeaderInOrderOfUserStatus() {
    const nav = document.getElementById(headerNavId);
    /*if(!nav) { throw new Error('Could not find the header navigation'); }*/
    if (!nav) {
      return;
    }

    const setNavLinksEvt = new Event(setNavLinksEvent);
    nav.dispatchEvent(setNavLinksEvt);
  }

  // TODO: @Smell: sacar el if y enviar métodos que encapsulen y resuelvan acá, sin intervención de quién llama a este método
  checkIfGuestUser() {
    const userData = this.getUserData();
    return new Promise((resolve, reject) => {
      if (!userData) {
        resolve("not_logged");
        return;
      }
      if (userData && !userData[userAccessToken]) {
        resolve("not_logged");
      } else {
        this.getRemoteUserData()
          .then((res) => {
            const regex = /@guest\.technology/gm;
            if (res && res.email && regex.test(res.email)) {
              resolve("is_guest_user");
            } else {
              resolve("is_logged_but_is_not_guest_user");
            }
          })
          .catch((err) => {
            reject(new Error(err));
          });
      }
    });
  }

  getTemporalDataPostRegister() {
    const value = sessionStorage.getItem(userTemporalDataPostRegister);
    if (!value) {
      return null;
    }
    return JSON.parse(value);
  }

  async updateRegisterData(userData) {
    const response = await this.api
      .updateRegisterData(userData)
      .then(async (res) => {
        if (!res) {
          return res;
        }
        let user = JSON.parse(localStorage.getItem(userDataLocalStorage));
        localStorage.removeItem(userDataLocalStorage);
        user.user.email = userData.email;
        user.user.cell_phone = userData.cell_phone;
        user.user.prefix_cell_phone = userData.prefix_cell_phone;
        user.user.country = userData.country;
        user.user.country_id = userData.country_id;
        localStorage.setItem(userDataLocalStorage, JSON.stringify(user));
        delete userData.country;
        sessionStorage.removeItem(userTemporalDataPostRegister);
        sessionStorage.setItem(
          userTemporalDataPostRegister,
          JSON.stringify(userData)
        );
        return res;
      })
      .catch((err) => {
        throw err;
      });
    return response;
  }
  
  async verificationSISA(userData) {
    return await this.api
      .verificationSISA(userData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
}

export default AuthService;
