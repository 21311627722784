import {
  apiURL_register,
  apiURL_users,
  apiURL_user_me,
  apiURL_user_update_cuit,
  apiURL_user_password,
  apiURL_medics,
  apiURL_config,
  apiURL_identification_types,
  apiURL_genders,
  apiURL_nonworking_days,
  apiURL_nonworking_days_collisions,
  apiURL_clinics,
  apiURL_posts,
  apiURL_posts_merged,
  apiURL_specialties,
  apiURL_patients,
  apiURL_timezones,
  apiURL_medics_me,
  apiURL_medic_patient,
  apiURL_messages,
  apiURL_appointments_videocall_token,
  apiURL_medic_patient_hash,
  apiURL_medics_interrupted_agenda,
  apiURL_user_cellphone,
  apiURL_cellphone_request_validation,
  apiURL_cellphone_validation,
  apiURL_clinics_domain,
  apiURL_clinics_user,
  apiURL_clinics_me,
  apiURL_medical_insurances,
  apiURL_medicines_search,
  apiURL_medical_prescriptions_request,
  apiURL_medic_code,
  apiURL_patient_code,
  apiURL_countries,
  apiURL_prescription_profiles_me,
  apiURL_prescription_profiles,
  apiURL_mp_authorize,
  apiURL_payment_methods,
  apiURL_user_check_payment_data,
  apiURL_stats_me,
  apiURL_services_me,
  apiURL_medical_practices,
  apiURL_medical_practices_search,
  apiURL_practices_combos_me,
  apiURL_practices_combos,
  apiURL_user_professional_prefixes,
  apiURL_professional_registration_types,
  apiURL_parent_roles,
  apiURL_faqs_categories,
  apiURL_faqs,
  apiURL_user_privacy,
  apiURL_user_prevent_reprint_prescriptions,
  apiURL_identification_types_by_country,
  apiURL_bank_data_account_types,
  apiURL_schedules_me,
  apiURL_signatures_me,
  apiURL_stamps_me,
  apiURL_stamps,
  apiURL_movements_me,
  apiURL_credit_cards_me,
  apiURL_appointments_me,
  apiURL_consultations_prices_me,
  apiURL_credit_cards,
  apiURL_appointments_check_config,
  apiURL_childs_me,
  apiURL_childs_medics,
  apiURL_childs,
  apiURL_bank_data_medic,
  apiURL_bank_data,
  apiURL_medics_search,
  apiURL_medics_search_by_specialty,
  apiURL_faqs_search,
  apiURL_plm_medicines_search,
  apiURL_vademecum_medicines_search,
  apiURL_plm_medicines_by_drug_id,
  apiURL_plm_drugs_search,
  apiURL_medicines_search_icd10,
  apiURL_medicines_get_icd10,
  apiURL_medicines_get_icd10_search,
  apiURL_medicines_presentations,
  apiURL_indications,
  apiURL_indications_by_consultation,
  apiURL_signatures,
  apiURL_movements_summary,
  apiURL_prescriptions_config,
  apiURL_prescriptions_test_profile,
  apiURL_prescriptions_requests_me,
  apiURL_prescriptions_requests,
  apiURL_prescriptions_request_status,
  apiURL_prescriptions_me,
  apiURL_prescriptions,
  apiURL_certificates_me,
  apiURL_certificates,
  apiURL_consultations,
  apiURL_consultations_complete,
  apiURL_consultations_status,
  apiURL_consultations_prices,
  apiURL_consultations_prices_final,
  apiURL_medical_orders_templates,
  apiURL_medical_orders,
  apiURL_medical_orders_by_consultation,
  apiURL_medical_certificates_templates,
  apiURL_medical_certificates,
  apiURL_medical_certificates_by_consultation,
  apiURL_schedules,
  apiURL_identification_image_base64,
  apiURL_prescription_profile_logo_image_base64,
  apiURL_prescription_profile_logo_image,
  apiURL_prescription_profile_signature_image,
  apiURL_user_profile_image_base64,
  apiURL_prescription_profile_signature_image_base64,
  apiURL_message_image_base64,
  apiURL_child_profile_image_base64,
  apiURL_auth_resend_email_verification,
  apiURL_patients_me,
  apiURL_auth_login,
  apiURL_qr,
  apiURL_patients_search,
  apiURL_medicines_search_public,
  apiURL_clinic_patients,
  apiURL_user_SISA_validations,
  apiURL_prescriptions_diagnostics,
  apiURL_practices_by_combos,
  apiURL_practices_combos_add,
  apiURL_practices_combos_delete,
  apiURL_practices_combos_delete_all,
  apiURL_patient_medical_insurances,
  apiURL_patient_medical_insurances_number,
  apiURL_patient_medical_insurances_selected,
  apiURL_medical_licenses_configurations,
  apiURL_password_request_api,
  apiURL_medics_prescriptions_profiles,
  apiURL_prescription_profiles_by_slug,
  apiURL_search_medical_prescriptions,
  apiURL_search_medical_certificates,
  apiURL_permissions_me,
  apiUrl_hubspot_get_identified_visitor_token,
  apiUrl_user_close_all_sessions,
  apiURL_countries_by_code,
  apiURL_patients_search_paginated,
  apiURL_reset_password,
  apiURL_reset_pass_validate,
  apiURL_medical_insurances_none,
  apiURL_medical_licenses,
  apiURL_user_SISA_sync,
  apiURL_update_medical_licenses_image,
  apiURL_patients_by_clinic,
  apiURL_external_validator,
  apiURL_email_validation,
  apiURL_resend_email_validation,
  apiURL_validate_smtp_domain,
  apiURL_auth_get_time_left_verification,
  apiURL_user_verification
} from "../models/api-urls";
import { userDataLocalStorage } from "../models/constants";

// import unregister from './apiService.config'; // No estamos usando el register(), pero importarlo hace que funcione el interceptor
import axiosInstance from "./../modules/ApiService.interceptor";

import AuthService from "./authService";
import axios from "axios";
import moment from "moment/moment";
import { userAccessToken } from "../models/constants";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

function CustomException(exp) {
  this.status = exp.status;
  this.message = exp.message;
  this.errors = exp.errors;
}

class APIService {
  callAuthServiceLogout() {
    const auth = new AuthService();
    auth.logout(true);
  }

  parseError(err) {
    let msg = "";

    msg = err && err.data && err.data.message ? err.data.message : err.message;

    if (err && err.errors && err.errors.length) {
      err.errors.map((e) => {
        if (e !== msg) {
          msg += ". " + e;
        }
      });
    }

    return msg;
  }

  setParams = (urlStr, params) => {
    const url = new URL(urlStr);
    // params = {lat:35.696233, long:139.570431};
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }
    return url;
  };

  getConfig = async () => {
    const response = await fetch(apiURL_config, {
      method: "GET",
      headers,
    });
    const body = await response.json();

    /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getCellphoneValidation = async () => {
    const response = await fetch(apiURL_cellphone_request_validation, {
      method: "GET",
      headers,
    });
    const body = await response.json();

    /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  postCellphoneValidation = async (data) => {
    const response = await fetch(apiURL_cellphone_validation, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });
    const body = await response.json();

    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getClinics = async () => {
    const response = await fetch(apiURL_clinics, {
      method: "GET",
      headers,
    });
    const body = await response.json();

    /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getMyClinics = async (data) => {
    const response = await axiosInstance.get(apiURL_clinics_me, {
      params: data,
    });
    return response.data;
  };

  getClinicsByUser = async (id) => {
    const response = await fetch(apiURL_clinics_user + "/" + id, {
      method: "GET",
      headers,
    });
    const body = await response.json();

    /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getClinic = async (domain) => {
    const response = await fetch(apiURL_clinics_domain + "?domain=" + domain, {
      method: "GET",
      headers,
    });
    const body = await response.json();

    /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getClinicById = async (id) => {
    const response = await fetch(apiURL_clinics + "/" + id, {
      method: "GET",
      headers,
    });
    const body = await response.json();

    /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  postClinic = async (data) => {
    const response = await fetch(apiURL_clinics, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });
    const body = await response.json();

    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  putClinic = async (id, data) => {
    const response = await fetch(apiURL_clinics + "/" + id, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    });
    const body = await response.json();

    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  deleteClinic = async (id) => {
    const url = new URL(apiURL_clinics);
    const response = await fetch(url + "/" + id, {
      method: "DELETE",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getMergedAnnouncements = async (params) => {
    let url = new URL(apiURL_posts_merged);
    if (params) {
      url = this.setParams(url, params);
    }
    const response = await fetch(url, {
      method: "GET",
      headers,
    });
    const body = await response.json();

    /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  postAnnouncement = async (data) => {
    const response = await fetch(apiURL_posts, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });
    const body = await response.json();

    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  deleteAnnouncement = async (id) => {
    const url = new URL(apiURL_posts);
    const response = await fetch(url + "/" + id, {
      method: "DELETE",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  register = async (data) => {
    const response = await fetch(apiURL_register, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });
    const body = await response.json();

    if (response.status >= 300)
      throw Error(
        body.errors && body.errors.length ? body.errors[0] : body.message
      );

    return body;
  };

  updateRegisterData = async (userData) => {
    let user = localStorage.getItem(userDataLocalStorage);
    if (!user) {
      return false;
    }
    user = JSON.parse(user);
    headers.authorization = "Bearer " + user.token;
    const response = await fetch(apiURL_users, {
      method: "PUT",
      headers,
      body: JSON.stringify(userData),
    });

    const body = await response.json();
    if (response.status >= 300) {
      throw new Error(
        body.errors && body.errors.length ? body.errors : body.message
      );
    }
    return body;
  };

  updateCellphone = async (data) => {
    const response = await fetch(apiURL_user_cellphone, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    });
    const body = await response.json();

    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  updatePassword = async (
    old_password,
    new_password,
    new_password_confirmation
  ) => {
    const response = await fetch(apiURL_user_password, {
      method: "PUT",
      headers,
      body: JSON.stringify({
        old_password,
        new_password,
        new_password_confirmation,
      }),
    });
    const body = await response.json();

    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getUserMe = async () => {
    const response = await axiosInstance.get(apiURL_user_me);
    if (response.status >= 401) {
      this.callAuthServiceLogout();
      return false;
    }
    return response.data;
  };

  getUserById = async (id) => {
    const response = await fetch(apiURL_users + "/" + id, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getNonWorkingDays = async (params) => {
    let url = new URL(apiURL_nonworking_days);
    if (params) {
      url = this.setParams(url, params);
    }
    const response = await fetch(url, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  postNonWorkingDays = async (params) => {
    let url = new URL(apiURL_nonworking_days);
    if (params) {
      url = this.setParams(url, params);
    }
    const response = await fetch(url, {
      method: "POST",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  deleteNonWorkingDays = async (id) => {
    const url = new URL(apiURL_nonworking_days);
    const response = await fetch(url + "/" + id, {
      method: "DELETE",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getNonWorkingDaysCollisions = async (params) => {
    let url = new URL(apiURL_nonworking_days_collisions);
    if (params) {
      url = this.setParams(url, params);
    }
    const response = await fetch(url, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getMedics = async (params) => {
    let url = new URL(apiURL_medics);
    if (params) {
      url = this.setParams(url, params);
    }
    const response = await fetch(url, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getMedicsByClinic = async (clinicId) => {
    const response = await fetch(apiURL_medics + "/clinic/" + clinicId, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getMedicPatientBounding = async (params) => {
    let url = new URL(apiURL_medic_patient);
    if (params) {
      url = this.setParams(url, params);
    }
    const response = await fetch(url, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getMedicPatientBoundingByHash = async (hash) => {
    const response = await fetch(apiURL_medic_patient_hash + "/" + hash, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getMedicById = async (medicId) => {
    const response = await fetch(apiURL_medics + "/" + medicId, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getVideocallData = async (hash) => {
    const response = await fetch(
      apiURL_appointments_videocall_token + "/" + hash,
      {
        method: "GET",
        headers,
      }
    );
    const body = await response.json();
    if (response.status === 401) {
      throw "401";
      // return;
    }
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  postMedic = async (data) => {
    const response = await fetch(apiURL_medics, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });
    const body = await response.json();

    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  putInterruptedAgenda = async (userId, data) => {
    const response = await fetch(
      apiURL_medics_interrupted_agenda + "/" + userId,
      {
        method: "PUT",
        headers,
        body: JSON.stringify(data),
      }
    );
    const body = await response.json();

    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getCountryById = async (countryId) => {
    const response = await fetch(apiURL_countries + "/" + countryId, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getPrescriptionsProfiles = async (medicId) => {
    const response = await fetch(
      apiURL_medics_prescriptions_profiles + "/" + medicId,
      {
        method: "GET",
        headers,
      }
    );
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  getTimezones = async () => {
    const response = await fetch(apiURL_timezones, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  //Post medical prescription request
  postMedicalPrescriptionRequest = async (data) => {
    const response = await fetch(apiURL_medical_prescriptions_request, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });
    const body = await response.json();

    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  //Get medic by code
  getMedicByCode = async (code) => {
    const response = await fetch(apiURL_medic_code + "/" + code, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    if (response.status >= 300) throw Error(body.message);

    return body;
  };

  //Post medical patient
  postPatientByCode = async (data) => {
    const response = await fetch(apiURL_patient_code, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });
    const body = await response.json();

    if (response.status >= 400) throw Error(body.errors);

    return body;
  };

  // ======================
  // === From RCTA APP ====
  // === FROM HERE DOWN ===
  getMyPrescriptionProfiles = async () => {
    const response = await axiosInstance.get(apiURL_prescription_profiles_me);
    return response.data;
  };

  getPrescriptionProfile = async (prescriptionProfileId) => {
    const response = await axiosInstance.get(
      apiURL_prescription_profiles + "/" + prescriptionProfileId
    );
    return response.data;
  };

  postPrescriptionProfile = async (data) => {
    const response = await axiosInstance.post(
      apiURL_prescription_profiles,
      data
    );
    return response.data;
  };

  putPrescriptionProfile = async (id, data) => {
    const response = await axiosInstance.put(
      apiURL_prescription_profiles + "/" + id,
      data
    );
    return response.data;
  };

  deletePrescriptionProfile = async (id) => {
    const response = await axiosInstance.delete(
      apiURL_prescription_profiles + "/" + id
    );
    return response.data;
  };

  getMercadoPagoAuthorization = async () => {
    const response = await axiosInstance.get(apiURL_mp_authorize);
    return response.data;
  };

  deleteMercadoPagoAuthorization = async () => {
    const response = await axiosInstance.delete(apiURL_mp_authorize);
    return response.data;
  };

  getPaymentMethods = async (medicId) => {
    const response = await axiosInstance.get(apiURL_payment_methods, {
      params: { medic_id: medicId },
    });
    return response.data;
  };

  getUser = async (id) => {
    const response = await axiosInstance.get(apiURL_users + id);
    return response.data;
  };

  checkPaymentData = async (id) => {
    const response = await axiosInstance.get(apiURL_user_check_payment_data);
    return response.data;
  };

  getUserByMe = async () => {
    const response = await axiosInstance.get(apiURL_user_me);
    return response.data;
  };

  getMyStats = async () => {
    const response = await axiosInstance.get(apiURL_stats_me);
    return response.data;
  };

  getMyServices = async () => {
    const response = await axiosInstance.get(apiURL_services_me);
    return response.data;
  };

  getMedicalPractices = async (data) => {
    const response = await axiosInstance.get(apiURL_medical_practices, {
      params: data,
    });
    return response.data;
  };

  searchMedicalPractices = async (data) => {
    const response = await axiosInstance.get(apiURL_medical_practices_search, {
      params: data,
    });
    return response.data;
  };

  getMyPracticesCombos = async (data) => {
    const response = await axiosInstance.get(apiURL_practices_combos_me, {
      params: data,
    });
    return response.data;
  };

  postCombo = async (data) => {
    const response = await axiosInstance.post(apiURL_practices_combos, data);
    return response.data;
  };

  deleteCombo = async (id) => {
    const response = await axiosInstance.delete(
      apiURL_practices_combos + "/" + id
    );
    return response.data;
  };

  updateCombo = async (id, data) => {
    const response = await axiosInstance.put(
      apiURL_practices_combos + "/" + id,
      data
    );
    return response.data;
  };

  getPracticeByCombos = async (id) => {
    const response = await axiosInstance.get(
      apiURL_practices_by_combos + "/" + id
    );
    return response.data;
  };

  addPractice = async (objectData) => {
    const response = await axiosInstance.post(
      apiURL_practices_combos_add,
      objectData
    );
    return response.data;
  };

  deletePractice = async (combo_id, practice_id) => {
    const response = await axiosInstance.post(
      apiURL_practices_combos_delete + "/" + combo_id + "/" + practice_id
    );
    return response.data;
  };

  deleteAllPractice = async (combo_id) => {
    const response = await axiosInstance.get(
      apiURL_practices_combos_delete_all + "/" + combo_id
    );
    return response.data;
  };

  getMedicalInsurances = async (insurancePermission = true) => {
    const url = insurancePermission
      ? apiURL_medical_insurances
      : apiURL_medical_insurances_none;
    const response = await axiosInstance.get(url);
    return response.data;
  };

  getPrescriptionProfilesBySlug = async (slug) => {
    const response = await axiosInstance.get(
      apiURL_prescription_profiles_by_slug + "/" + slug
    );
    return response.data;
  };

  getMedicalInsurancesByCountryId = async (countryId) => {
    const response = await axiosInstance.get(
      apiURL_medical_insurances + "/" + countryId
    );
    return response.data;
  };

  getUserProfessionalPrefixes = async () => {
    const response = await axiosInstance.get(apiURL_user_professional_prefixes);
    return response.data;
  };

  getSpecialties = async () => {
    const response = await axiosInstance.get(apiURL_specialties);
    return response.data;
  };

  searchSpecialties = async (data) => {
    const response = await axiosInstance.get(apiURL_specialties, {
      params: data,
    });
    return response.data;
  };

  searchDiagnostics = async (text) => {
    const response = await axiosInstance.get(
      `${apiURL_prescriptions_diagnostics}${text}`
    );
    return response.data;
  };

  getProfessionalRegistrationTypes = async () => {
    const response = await axiosInstance.get(
      apiURL_professional_registration_types
    );
    return response.data;
  };

  getGenders = async () => {
    const response = await axiosInstance.get(apiURL_genders);
    return response.data;
  };

  getParentRoles = async () => {
    const response = await axiosInstance.get(apiURL_parent_roles);
    return response.data;
  };

  getFAQsCategories = async () => {
    const response = await axiosInstance.get(apiURL_faqs_categories);
    return response.data;
  };

  getFAQs = async (categoryId) => {
    const response = await axiosInstance.get(
      apiURL_faqs + "?category_id=" + categoryId
    );
    return response.data;
  };

  getFAQ = async (id) => {
    const response = await axiosInstance.get(apiURL_faqs + id);
    return response.data;
  };

  putFAQ = async (id, faq) => {
    const response = await axiosInstance.put(apiURL_faqs + id, faq);
    return response.data;
  };

  setPrivacy = async (bool) => {
    const obj = {
      privacy: bool,
    };

    const response = await axiosInstance.put(apiURL_user_privacy, obj);
    return response.data;
  };

  setPreventReprintPrescriptions = async (bool) => {
    const obj = {
      prevent_reprint_prescriptions: bool,
    };

    const response = await axiosInstance.put(
      apiURL_user_prevent_reprint_prescriptions,
      obj
    );
    return response.data;
  };

  getIdentificationTypes = async () => {
    const response = await axiosInstance.get(apiURL_identification_types);
    return response.data;
  };

  getIdentificationTypesByCountryId = async (countryId) => {
    const response = await axiosInstance.get(
      apiURL_identification_types_by_country + "/" + countryId
    );
    return response.data;
  };

  getBankAccountTypes = async () => {
    const response = await axiosInstance.get(apiURL_bank_data_account_types);
    return response.data;
  };

  getMySchedules = async () => {
    const response = await axiosInstance.get(apiURL_schedules_me);
    return response.data;
  };

  getMyPatients = async (pageUrl = "", data = {}) => {
    const response = await axiosInstance.get(pageUrl || apiURL_patients_me, {
      params: {
        ...data,
        paginated: true,
      },
    });
    return response.data;
  };

  searchPatients = async (data) => {
    const response = await axiosInstance.get(apiURL_patients_search, {
      params: {
        ...data,
      },
    });
    return response.data;
  };

  searchPatientsPaginated = async (data, page = 0) => {
    const response = await axiosInstance.get(apiURL_patients_search_paginated, {
      params: {
        ...data,
        page: page,
      },
    });
    return response.data;
  };

  searchClinicPatients = async (data) => {
    const response = await axiosInstance.get(apiURL_clinic_patients, {
      params: data,
    });
    return response.data;
  };

  getClinicPatient = async (identification, clinic_id) => {
    console.log(`${apiURL_external_validator}/${identification}/${clinic_id}`);
    const response = await axiosInstance.get(
      `${apiURL_external_validator}/${identification}/${clinic_id}`
    );
    return response.data;
  };

  getMyMedics = async () => {
    const response = await axiosInstance.get(apiURL_medics_me);
    return response.data;
  };

  getMySignatures = async () => {
    const response = await axiosInstance.get(apiURL_signatures_me);
    return response.data;
  };

  getMyStamps = async () => {
    const response = await axiosInstance.get(apiURL_stamps_me);
    return response.data;
  };

  postStamp = async (text) => {
    const data = {
      text,
    };
    const response = await axiosInstance.post(apiURL_stamps, data);
    return response.data;
  };

  getMyMovements = async () => {
    const response = await axiosInstance.get(apiURL_movements_me);
    return response.data;
  };

  // 'approved', 'tokenized', 'rejected'
  getMyPayments = async (status) => {
    let apiURL = apiURL_credit_cards_me;
    if (status) {
      apiURL += "?status=" + status;
    }
    const response = await axiosInstance.get(apiURL);
    return response.data;
  };

  getMyAppointments = async () => {
    const response = await axiosInstance.get(apiURL_appointments_me);
    return response.data;
  };

  getMyConsultationsPrices = async () => {
    const response = await axiosInstance.get(apiURL_consultations_prices_me);
    return response.data;
  };

  postCreditCard = async () => {
    const response = await axiosInstance.post(apiURL_credit_cards);
    return response.data;
  };

  getMedic = async (id) => {
    const response = await axiosInstance.get(apiURL_medics + "id/" + id);
    return response.data;
  };

  getCalendarConfig = async (id) => {
    const response = await axiosInstance.get(
      apiURL_appointments_check_config + id
    );
    return response.data;
  };

  getMyChildren = async () => {
    const response = await axiosInstance.get(apiURL_childs_me);
    return response.data;
  };

  getChildMedics = async (childId) => {
    const response = await axiosInstance.get(apiURL_childs_medics + childId);
    return response.data;
  };

  getChildsByPatientId = async (id) => {
    // /childs/patient/$patient_id
    const response = await axiosInstance.get(apiURL_childs + "patient/" + id);
    return response.data;
  };

  getMedicBankData = async (userId) => {
    const response = await axiosInstance.get(apiURL_bank_data_medic + userId);
    return response.data;
  };

  postBankData = async (data) => {
    const response = await axiosInstance.post(apiURL_bank_data, data);
    return response.data;
  };

  putBankData = async (id, data) => {
    const response = await axiosInstance.put(apiURL_bank_data + id, data);
    return response.data;
  };

  deleteBankData = async (id) => {
    const response = await axiosInstance.delete(apiURL_bank_data + id);
    return response.data;
  };

  searchMedics = async (query) => {
    const response = await axiosInstance.get(apiURL_medics_search + query);
    return response.data;
  };

  searchMedicsBySpecialtyId = async (specialtyId) => {
    const response = await axiosInstance.get(
      apiURL_medics_search_by_specialty + specialtyId
    );
    return response.data;
  };

  searchFaqs = async (query, categoryId) => {
    const response = await axiosInstance.get(
      apiURL_faqs_search + query + "&category_id=" + categoryId
    );
    return response.data;
  };

  searchMedicines = async (data, isPublic) => {
    const response = await axiosInstance.get(
      isPublic ? apiURL_medicines_search_public : apiURL_medicines_search,
      {
        params: data,
      }
    );
    return response.data;
  };

  searchMedicinesByBrand = async (query) => {
    const response = await axiosInstance.get(
      apiURL_plm_medicines_search + query
    );
    return response.data;
  };

  searchMedicinesByBrandNative = async (query) => {
    const response = await axiosInstance.get(
      apiURL_vademecum_medicines_search + query
    );
    return response.data;
  };

  searchMedicinesByDrugId = async (drugId) => {
    const response = await axiosInstance.get(
      apiURL_plm_medicines_by_drug_id + drugId
    );
    return response.data;
  };

  searchDrugs = async (query) => {
    const response = await axiosInstance.get(apiURL_plm_drugs_search + query);
    return response.data;
  };

  searchMedicinesICD10 = async (query) => {
    const response = await axiosInstance.get(
      apiURL_medicines_search_icd10 + query
    );
    return response.data;
  };

  getMedicinesICD10 = async (query) => {
    const response = await axiosInstance.get(
      apiURL_medicines_get_icd10 + query
    );
    return response.data;
  };

  getMedicinesSearchICD10 = async (query) => {
    const response = await axiosInstance.get(
      apiURL_medicines_get_icd10_search + query
    );
    return response.data;
  };

  getMedicinePresentations = async (medicineId) => {
    const response = await axiosInstance.get(
      apiURL_medicines_presentations + medicineId
    );
    return response.data;
  };

  getChild = async (id) => {
    const response = await axiosInstance.get(apiURL_childs + id);
    return response.data;
  };

  postChild = async (data) => {
    const response = await axiosInstance.post(apiURL_childs, data);
    return response.data;
  };

  putChild = async (id, data) => {
    const response = await axiosInstance.put(apiURL_childs + id, data);
    return response.data;
  };

  deleteChild = async (id) => {
    const response = await axiosInstance.delete(apiURL_childs + id);
    return response.data;
  };

  getIndications = async (id) => {
    const response = await axiosInstance.get(apiURL_indications + id);
    return response.data;
  };

  getIndicationsByConsultationId = async (id) => {
    const response = await axiosInstance.get(
      apiURL_indications_by_consultation + id
    );
    return response.data;
  };

  postIndication = async (data) => {
    const response = await axiosInstance.post(apiURL_indications, data);
    return response.data;
  };

  putIndication = async (data, id) => {
    const response = await axiosInstance.put(apiURL_indications + id, data);
    return response.data;
  };

  deleteIndication = async (id) => {
    const response = await axiosInstance.delete(apiURL_indications + id);
    return response.data;
  };

  getSignatureByMedicId = async (medicId) => {
    const response = await axiosInstance.get(
      apiURL_signatures + "medic/" + medicId
    );
    return response.data;
  };

  getStampByMedicId = async (medicId) => {
    const response = await axiosInstance.get(
      apiURL_stamps + "medic/" + medicId
    );
    return response.data;
  };

  deleteSignature = async () => {
    const response = await axiosInstance.delete(apiURL_signatures);
    return response.data;
  };

  deleteStamp = async () => {
    const response = await axiosInstance.delete(apiURL_stamps);
    return response.data;
  };

  getMovementsSummary = async () => {
    const response = await axiosInstance.get(apiURL_movements_summary);
    return response.data;
  };

  getPrescriptionsConfig = async () => {
    const response = await axiosInstance.get(apiURL_prescriptions_config);
    return response.data;
  };

  putPrescriptionsConfig = async (data) => {
    const response = await axiosInstance.put(apiURL_prescriptions_config, data);
    return response.data;
  };

  testPrescription = async (id) => {
    const response = await axiosInstance.get(
      apiURL_prescriptions_test_profile + "/" + id
    );
    return response.data;
  };

  /* ====================== */
  /* PRESCRIPTIONS REQUESTS */
  /* ====================== */

  getMyPrescriptionsRequests = async (data, pageUrl: any) => {
    const url = apiURL_prescriptions_requests_me;
    const response = await axiosInstance.get(
      pageUrl || apiURL_prescriptions_requests_me,
      {
        params: data,
      }
    );
    return response.data;
  };

  getPrescriptionRequest = async (id) => {
    const response = await axiosInstance.get(
      apiURL_prescriptions_requests + "/" + id
    );
    return response.data;
  };

  putPrescriptionRequestStatus = async (id, data) => {
    const response = await axiosInstance.put(
      apiURL_prescriptions_request_status + "/" + id,
      data
    );
    return response.data;
  };

  /* ============= */
  /* PRESCRIPTIONS */
  /* ============= */

  getMyPrescriptions = async (data) => {
    const response = await axiosInstance.get(apiURL_prescriptions_me, {
      params: data,
    });
    return response.data;
  };

  getMyPrescriptionsPaginated = async (data, pageUrl) => {
    const url = apiURL_prescriptions_me + "/paginated";
    const response = await axiosInstance.get(pageUrl || url, {
      params: data,
    });
    return response.data;
  };

  getPrescription = async (id) => {
    const response = await axiosInstance.get(apiURL_prescriptions + "/" + id);
    return response.data;
  };

  getPatientPrescriptions = async (id) => {
    const response = await axiosInstance.get(
      apiURL_prescriptions + "/patient/" + id
    );
    return response.data;
  };

  getPatientPrescriptionsPaginated = async (id, pageUrl) => {
    const url = apiURL_prescriptions + "/patient/paginated/" + id;
    const response = await axiosInstance.get(pageUrl || url);
    return response.data;
  };

  getSearchPrescriptions = async (data, page = 0) => {
    const response = await axiosInstance.get(
      apiURL_search_medical_prescriptions,
      {
        params: {
          ...data,
          page: page,
        },
      }
    );
    return response.data;
  };

  postPrescription = async (data) => {
    const response = await axiosInstance.post(apiURL_prescriptions, data);
    return response.data;
  };

  putPrescription = async (data, id) => {
    const response = await axiosInstance.put(apiURL_prescriptions + id, data);
    return response.data;
  };

  deletePrescription = async (id) => {
    const response = await axiosInstance.delete(apiURL_prescriptions + id);
    return response.data;
  };

  postPrescriptionEmail = async (documents = [], data) => {
    const ids = [];
    documents.map((doc) => {
      ids.push(doc.prescriptionId);
    });
    const response = await axiosInstance.post(
      apiURL_prescriptions + "/send_email/" + ids.join(","),
      data
    );
    return response.data;
  };

  postQREmail = async (data) => {
    const response = await axiosInstance.post(apiURL_qr + "/send_email", data);
    return response.data;
  };

  getQRToShow = async (data) => {
    const response = await axiosInstance.get(apiURL_qr + "/show_qr");
    return response.data;
  };

  /* ============ */
  /* CERTIFICATES */
  /* ============ */

  getMyCertificates = async (data) => {
    const response = await axiosInstance.get(apiURL_certificates_me, {
      params: data,
    });
    return response.data;
  };

  getMyCertificatesPaginated = async (data, pageUrl) => {
    const url = apiURL_certificates_me + "/paginated";
    const response = await axiosInstance.get(pageUrl || url, {
      params: data,
    });
    return response.data;
  };

  getSearchCertificates = async (data, page = 0) => {
    const response = await axiosInstance.get(
      apiURL_search_medical_certificates,
      {
        params: {
          ...data,
          page: page,
        },
      }
    );
    return response.data;
  };

  getPatientCertificates = async (id) => {
    const response = await axiosInstance.get(
      apiURL_certificates + "/patient/" + id
    );
    return response.data;
  };

  getPatientCertificatesPaginated = async (id, pageUrl) => {
    const url = apiURL_certificates + "/patient/paginated/" + id;
    const response = await axiosInstance.get(pageUrl || url);
    return response.data;
  };

  postCertificate = async (data) => {
    const response = await axiosInstance.post(apiURL_certificates, data);
    return response.data;
  };

  putCertificate = async (data, id) => {
    const response = await axiosInstance.put(apiURL_certificates + id, data);
    return response.data;
  };

  deleteCertificate = async (id) => {
    const response = await axiosInstance.delete(apiURL_certificates + id);
    return response.data;
  };

  postCertificateEmail = async (documents = [], data) => {
    const ids = [];
    documents.map((doc) => {
      ids.push(doc.prescriptionId);
    });
    const response = await axiosInstance.post(
      apiURL_certificates + "/send_email/" + ids.join(","),
      data
    );
    return response.data;
  };

  getConsultationByChildId = async (id) => {
    const response = await axiosInstance.get(
      apiURL_consultations + "child/" + id
    );
    return response.data;
  };

  getConsultationsByPatient = async (id) => {
    const response = await axiosInstance.get(
      apiURL_consultations + "patient/" + id
    );
    return response.data;
  };

  getConsultation = async (id) => {
    const response = await axiosInstance.get(apiURL_consultations + id);
    return response.data;
  };

  postConsultation = async (data) => {
    const response = await axiosInstance.post(apiURL_consultations, data);
    return response.data;
  };

  completeConsultation = async (data) => {
    const response = await axiosInstance.post(
      apiURL_consultations_complete,
      data
    );
    return response.data;
  };

  putConsultation = async (id, data) => {
    const response = await axiosInstance.put(apiURL_consultations + id, data);
    return response.data;
  };

  putConsultationStatus = async (data) => {
    const response = await axiosInstance.put(apiURL_consultations_status, data);
    return response.data;
  };

  deleteConsultation = async (id) => {
    const response = await axiosInstance.delete(apiURL_consultations + id);
    return response.data;
  };

  deletePayment = async (id) => {
    const response = await axiosInstance.delete(apiURL_credit_cards + id);
    return response.data;
  };

  getConsultationPrice = async (id) => {
    const response = await axiosInstance.get(apiURL_consultations_prices + id);
    return response.data;
  };

  getConsultationPriceCalculateFinal = async (price) => {
    const response = await axiosInstance.get(
      apiURL_consultations_prices_final + "?price=" + price
    );
    return response.data;
  };

  postConsultationPrice = async (data) => {
    const response = await axiosInstance.post(
      apiURL_consultations_prices,
      data
    );
    return response.data;
  };

  putConsultationPrice = async (id, data) => {
    const response = await axiosInstance.put(
      apiURL_consultations_prices + id,
      data
    );
    return response.data;
  };

  getMedicalOrdersTemplates = async () => {
    const response = await axiosInstance.get(apiURL_medical_orders_templates);
    return response.data;
  };

  getMedicalOrders = async (id) => {
    const response = await axiosInstance.get(apiURL_medical_orders + id);
    return response.data;
  };

  getMedicalOrdersByConsultationId = async (id) => {
    const response = await axiosInstance.get(
      apiURL_medical_orders_by_consultation + id
    );
    return response.data;
  };

  postMedicalOrder = async (data) => {
    const response = await axiosInstance.post(apiURL_medical_orders, data);
    return response.data;
  };

  putMedicalOrder = async (data, id) => {
    const response = await axiosInstance.put(apiURL_medical_orders + id, data);
    return response.data;
  };

  deleteMedicalOrder = async (id) => {
    const response = await axiosInstance.delete(apiURL_medical_orders + id);
    return response.data;
  };

  getMedicalCertificatesTemplates = async () => {
    const response = await axiosInstance.get(
      apiURL_medical_certificates_templates
    );
    return response.data;
  };

  getMedicalCertificate = async (id) => {
    const response = await axiosInstance.get(
      apiURL_medical_certificates + "/" + id
    );
    return response.data;
  };

  getMedicalCertificatesByConsultationId = async (id) => {
    const response = await axiosInstance.get(
      apiURL_medical_certificates_by_consultation + id
    );
    return response.data;
  };

  postMedicalCertificate = async (data) => {
    const response = await axiosInstance.post(
      apiURL_medical_certificates,
      data
    );
    return response.data;
  };

  putMedicalCertificate = async (data, id) => {
    const response = await axiosInstance.put(
      apiURL_medical_certificates + id,
      data
    );
    return response.data;
  };

  deleteMedicalCertificate = async (id) => {
    const response = await axiosInstance.delete(
      apiURL_medical_certificates + id
    );
    return response.data;
  };

  getMessages = async (consultationId) => {
    const response = await axiosInstance.get(
      apiURL_messages + "?consultation_id=" + consultationId
    );
    // const response = await axiosInstance.get('http://192.168.0.14:9001/v1/messages?booking_id=' + consultationId);
    return response.data;
  };

  updateUser = async (userData) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(apiURL_user_me, userData)
        .then((res) => {
          resolve(res.data);
        })
        .catch(function (error) {
          if (error.response) {
            reject(new CustomException(error.response.data));
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            reject(new Error(error.request));
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            reject(new Error(error.message));
          }
        });
    });
  };

  updateCuit = async (data) => {
    return await axiosInstance.put(apiURL_user_update_cuit, data);
  };

  updatePasword = async (passwordData) => {
    const response = await axiosInstance.put(
      apiURL_user_password,
      passwordData
    );
    return response.data;
  };

  getPatientImages = async (id) => {
    const response = await axiosInstance.get(
      apiURL_patients + "/" + id + "/images"
    );
    return response.data;
  };

  deletePatientImage = async (patientId, imageId) => {
    const response = await axiosInstance.delete(
      apiURL_patients + "/" + patientId + "/images/" + imageId
    );
    return response.data;
  };

  deletePatient = async (id) => {
    const response = await axiosInstance.delete(apiURL_patients + "/" + id);
    return response.data;
  };

  getPatient = async (id) => {
    const response = await axiosInstance.get(apiURL_patients + "/" + id);
    return response.data;
  };

  getPatientPublic = async (medicToken, patientToken, errorCode) => {
    const response = await axiosInstance.get(
      apiURL_patients +
        "/public/" +
        medicToken +
        "/" +
        patientToken +
        "?error_code=" +
        errorCode
    );
    return response.data;
  };

  getPatientSharerUpdaterLink = async (patientId, errorCode) => {
    const response = await axiosInstance.get(
      apiURL_patients +
        "/sharer_link_updater/" +
        patientId +
        "?error_code=" +
        errorCode
    );
    return response.data;
  };

  getPatientSharerLink = async () => {
    const response = await axiosInstance.get(apiURL_patients + "/sharer_link");
    return response.data;
  };

  putPatient = async (id, data) => {
    const response = await axiosInstance.put(apiURL_patients + "/" + id, data);
    return response.data;
  };

  putPatientsPublic = async (medicToken, patientToken, errorCode, data) => {
    const response = await axiosInstance.put(
      apiURL_patients +
        "/public/" +
        medicToken +
        "/" +
        patientToken +
        "?error_code=" +
        errorCode,
      data
    );
    return response.data;
  };

  postPatient = async (data) => {
    const response = await axiosInstance.post(apiURL_patients, data);
    return response.data;
  };

  postPatientProfileImage = async (patientId, base64) => {
    const response = await axiosInstance.post(
      apiURL_patients + "/" + patientId + "/profile_image_base64",
      {
        profile_image: base64,
      }
    );
    return response.data;
  };

  postPatientAttachment = async (patientId, base64) => {
    const response = await axiosInstance.post(
      apiURL_patients + "/" + patientId + "/images_base64",
      {
        image: base64,
      }
    );
    return response.data;
  };

  updatePatientUser = async (userId, userData) => {
    return await axiosInstance.put(apiURL_patients + userId, userData);
  };

  getPatientMedicalInsurances = async (id) => {
    const response = await axiosInstance.get(
      apiURL_patient_medical_insurances + "/" + id
    );

    return response.data;
  };

  postPatientMedicalInsurance = async (patientId, data) => {
    const response = await axiosInstance.post(
      `${apiURL_patient_medical_insurances}/${patientId}`,
      data
    );
    return response.data;
  };

  putPatientMedicalInsurance = async (
    patientId,
    patient_medical_insurance_id,
    data
  ) => {
    const response = await axiosInstance.put(
      `${apiURL_patient_medical_insurances}/${patientId}/${patient_medical_insurance_id}`,
      data
    );
    return response.data;
  };

  putPatientNumberMedicalInsurance = async (
    patientId,
    patient_medical_insurance_id,
    data
  ) => {
    const response = await axiosInstance.put(
      `${apiURL_patient_medical_insurances_number}/${patientId}/${patient_medical_insurance_id}`,
      data
    );
    return response.data;
  };

  deletePatientMedicalInsurance = async (
    patientId,
    patient_medical_insurance_id
  ) => {
    const response = await axiosInstance.delete(
      `${apiURL_patient_medical_insurances}/${patientId}/${patient_medical_insurance_id}`
    );
    return response.data;
  };

  updateMedicalsInsurancesSelected = async (patientId, data) => {
    const response = await axiosInstance.put(
      `${apiURL_patient_medical_insurances_selected}/${patientId}`,
      data
    );
    return response.data;
  };

  updateScheduleStatus = async (value, scheduleId) => {
    const response = await axiosInstance.put(
      apiURL_schedules + scheduleId,
      value
    );
    return response.data;
  };

  updateIdentificationImage = async (base64) => {
    const response = await axiosInstance.post(
      apiURL_identification_image_base64,
      {
        identification_image: base64,
      }
    );
    return response.data;
  };

  updateLogoImage = async (prescriptionProfileId, base64) => {
    const response = await axiosInstance.post(
      apiURL_prescription_profile_logo_image_base64 +
        "/" +
        prescriptionProfileId,
      {
        logo_image: base64,
      }
    );
    return response.data;
  };

  deleteLogoImage = async (prescriptionProfileId) => {
    const response = await axiosInstance.delete(
      apiURL_prescription_profile_logo_image + "/" + prescriptionProfileId
    );
    return response.data;
  };

  deleteSignatureImage = async (prescriptionProfileId) => {
    const response = await axiosInstance.delete(
      apiURL_prescription_profile_signature_image + "/" + prescriptionProfileId
    );
    return response.data;
  };

  updateProfileImage = async (base64) => {
    const response = await axiosInstance.post(
      apiURL_user_profile_image_base64,
      {
        profile_image: base64,
      }
    );
    return response.data;
  };

  updateSignatureImage = async (prescriptionProfileId, base64) => {
    const response = await axiosInstance.post(
      apiURL_prescription_profile_signature_image_base64 +
        "/" +
        prescriptionProfileId,
      {
        signature: base64,
      }
    );
    return response.data;
  };

  updateMessageImage = async (consultationId, base64) => {
    return await axiosInstance.post(apiURL_message_image_base64, {
      consultation_id: consultationId,
      image: base64,
    });
  };

  updateChildProfileImage = async (childId, base64) => {
    return await axiosInstance.post(apiURL_child_profile_image_base64, {
      child_id: childId,
      profile_image: base64,
    });
  };

  passwordRequest = async (email) => {
    const response = await fetch(apiURL_password_request_api, {
      method: "POST",
      headers,
      body: JSON.stringify({
        email,
      }),
    });

    const body = await response.json();

    if (response.status >= 300) {
      throw new Error(body.message);
    }

    return body;
  };

  login = async (userData) => {
    const response = await fetch(apiURL_auth_login, {
      method: "POST",
      headers,
      body: JSON.stringify(userData),
    });

    const body = await response.json();

    if (response.status >= 300) {
      const error =
        "Ya posee un código de validación en su casilla de correo. Si no lo encuentra, por favor revise la carpeta de spam.";
      if (body.message.includes(error)) {
        const timeLeft = JSON.stringify(body.data.time_left);
        localStorage.setItem("token_time_left", timeLeft);
        throw new Error(body.message);
      }
      throw new Error(body.message);
    }

    return body;
  };

  getCountries = async () => {
    const response = await axiosInstance.get(apiURL_countries);
    return response.data;
  };

  getCountry = async (id) => {
    const response = await axiosInstance.get(apiURL_countries + "/" + id);
    return response.data;
  };

  getCountryByCode = async (code) => {
    const response = await axiosInstance.get(
      apiURL_countries_by_code + "/" + code
    );
    return response.data;
  };
  
  getTimeLeftToVerification = async () => {
    const response = await axiosInstance.get(
      apiURL_auth_get_time_left_verification
    );
    return response.data;
  };

  resendEmailVerification = async (email) => {
    const response = await fetch(apiURL_auth_resend_email_verification, {
      method: "POST",
      headers,
      body: JSON.stringify({
        email,
      }),
    });

    const body = await response.json();

    if (response.status >= 300) {
      throw new Error(body.message);
    }

    return body;
  };

  verificationSISA = async (data) => {
    let user = localStorage.getItem(userDataLocalStorage);
    if (!user) {
      return false;
    }
    user = JSON.parse(user);
    headers.authorization = "Bearer " + user.token;
    const response = await fetch(apiURL_user_SISA_validations + data, {
      method: "GET",
      headers,
    });
    const body = await response.json();
    return body;
  };

  getConfigurations = async () => {
    const response = await axiosInstance.get(
      apiURL_medical_licenses_configurations
    );
    return response.data;
  };

  getPermissionsMe = async () => {
    const response = await axiosInstance.get(apiURL_permissions_me);
    return response.data;
  };

  hubspotIdentifiedVisitorGetToken = async (
    loggedUserData,
    hubspotToken,
    hubspotTokenDate
  ) => {
    if (
      hubspotTokenDate &&
      moment().isSameOrBefore(moment(hubspotTokenDate).add(12, "hours"))
    ) {
      return { token: hubspotToken };
    }
    const response = await axiosInstance.get(
      apiUrl_hubspot_get_identified_visitor_token
    );
    return response.data;
  };

  closeAllSessions = async () => {
    const response = await axiosInstance.put(apiUrl_user_close_all_sessions);
    return response.data;
  };

  validateResetToken = async (token) => {
    const response = await axiosInstance.get(
      `${apiURL_reset_pass_validate}/${token}`
    );
    return response.data;
  };

  resetPassword = async (data) => {
    const response = await axiosInstance.post(apiURL_reset_password, data);
    return response.data;
  };
  postMedicalLicense = async (data) => {
    const response = await axiosInstance.post(apiURL_medical_licenses, data);
    return response.data;
  };

  putMedicalLicense = async (id, data) => {
    const response = await axiosInstance.put(
      apiURL_medical_licenses + "/" + id,
      data
    );
    return response.data;
  };

  getMedicalLicense = async (id) => {
    const response = await axiosInstance.get(
      apiURL_medical_licenses + "/" + id
    );
    return response.data;
  };

  deleteMedicalLicense = async (id) => {
    const response = await axiosInstance.delete(
      apiURL_medical_licenses + "/" + id
    );
    return response.data;
  };

  updateMedicalLicenseImage = async (medicalLicenseId, base64) => {
    const response = await axiosInstance.post(
      apiURL_update_medical_licenses_image + "/" + medicalLicenseId,
      {
        medical_license_image: base64,
      }
    );
    return response.data;
  };

  getMedicalsLicenses = async () => {
    const response = await axiosInstance.get(apiURL_medical_licenses + "/me");
    return response.data;
  };

  syncSISA = async (identification) => {
    const response = await axiosInstance.get(
      apiURL_user_SISA_sync + "/" + identification
    );
    return response.data;
  };

  postPatientByClinicId = async (data) => {
    const response = await axiosInstance.post(apiURL_patients_by_clinic, data);
    return response.data;
  };

  emailValidation = async (code) => {
    const response = await axiosInstance.get(apiURL_email_validation, {
      params: {
        code: code,
      },
    });

    return response.data;
  };

  resendEmailValidation = async (email) => {
    const response = await axiosInstance.post(apiURL_resend_email_validation, {
      email: email,
    });
    return response.data;
  };

  validateSmtpDomain = async (data) => {
    const response = await axiosInstance.get(apiURL_validate_smtp_domain, {
      params: data,
    });
    return response.data;
  };

  getIsUserVerified = async () => {
    const response = await axiosInstance.get(apiURL_user_verification);
    return response.data;
  }

}

//unregister();

export default APIService;
