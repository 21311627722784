import React, {Component} from 'react';
import locales_es from "../../locales/es";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import "./styles.css";

export default class SearchMedicines extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keySearch: '',
      searchResults: null
    };

    this.inputRef = React.createRef();

    this.api = new APIService();
    this.helpers = new Helpers();
  }

  componentDidUpdate(prevProps) {
    // Limpiar la lista cuando resetSearch cambie
    if (prevProps.resetSearch !== this.props.resetSearch) {
      this.setState({ keySearch: '', searchResults: null });
    }
  }


  onKeySearchChange(e) {
    this.setState({
      keySearch: e.target.value,
    }, () => {
      const _keySearch = JSON.parse(JSON.stringify(this.state.keySearch));
      if (_keySearch) {
        this.setState({searchResults: null}, () => {
          let objData = {}

          if(this.props.nroFinanciador > 0){
            objData =  {
              q: _keySearch,
              reg_no: true,
              nroFinanciador:this.props.nroFinanciador
            };
          }else{
            objData =  {
              q: _keySearch,
              reg_no: true,
            };
          }

          setTimeout(() => {
            if (_keySearch.length === e.target.value.length) {
              this.api
                .searchMedicines(objData, this.props.isPublic)
                .then(res => {
                  this.setState({searchResults: res.data});
                })
                .catch(err => {
                  this.props.showMainModal(
                    locales_es.infoModal.title,
                    this.helpers.getErrorMsg(err),
                  );
                });
            }
          }, 800); // 800
        });
      }
    });
  }

  render() {
    const {keySearch, searchResults} = this.state;
    const renderItem = (item, index) => {
      return (
        <tr key={"rendered-item-" + index} className="cursor-pointer dropdown"
            data-toggle="collapse" data-target={`#${'collapse-' + index}`} role="button"
            aria-expanded="false" aria-controls={'collapse-' + index}>
          <td className="text-muted">
            <span>{item.commercial_name}</span> <span>{item.drug}</span>
            <span className="float-right">
                <div className="icon sli-arrow-down"/>
              </span>
            <div className="cursor-pointer dropdown">
              <div className="collapse" id={'collapse-' + index}>
                <div className="p-4">
                  <p>Seleccione presentación...</p>
                  {item.presentations.map((presentation, presentationIndex) => {
                    return <div data-dismiss="modal" key={`presentation-${index}-${presentationIndex}`}
                                className="mb-1">
                      <a href="#" onClick={() => this.props.onSelectItem(item, presentationIndex)}>{presentation.name}</a>
                    </div>
                  })}
                </div>
              </div>
            </div>
          </td>
        </tr>
      )
    };

    return (
      <div style={{minHeight: 200}}>
        <div className="mt-3 mb-3">
          <input className="form-control autocomplete-control topbar-search" type="search" ref={this.inputRef}
                 placeholder={locales_es.searchMedicine} autoComplete="off" value={keySearch}
                 onChange={(e) => this.onKeySearchChange(e)}/>
        </div>
        {!keySearch ? (
          <p className="text-center">
            {locales_es.typeToSearch}...
          </p>
        ) : searchResults === null ? (
          <div className="spinner mt-5">
            <i className="icofont-spinner-alt-4 rotate"/>
          </div>
        ) : searchResults && searchResults.length ? (
          <table className="table table-hover">
            <tbody>
            {searchResults.map((result, index) => renderItem(result, index))}
            </tbody>
          </table>
        ) : (
          <p>
            {locales_es.searchNotFound}
          </p>
        )}
      </div>
    )
  }
}
